import { AlertColor } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppPaymentType } from '../../utils/device';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { printInvoice } from '../../utils/terminal';
import { getBasketSummary, getBasketTickets, getInvoiceData } from '../basket/selectors';
import { getAcceptedPaymentTypes } from '../layout/selectors';
import { CreateConfirmPaymentRequestModel, MapToRequestTicket } from './helpers';
import { CreatePaymentRequest, OrderStatus, PaymentType } from './models';
import { confirmPayment, getOrder, startPayment } from './operations';

export const useSinglePaymentType = (): readonly [PaymentType | null, boolean, boolean] => {
  const [singlePaymentType, setSinglePaymentType] = useState<PaymentType | null>(null);
  const acceptedPaymentTypes = useAppSelector((state) => getAcceptedPaymentTypes(state.layout));
  const basketSummary = useAppSelector((state) => getBasketSummary(state.basket));

  const displayCreditCardPayment: boolean = basketSummary.amount > 0 && acceptedPaymentTypes?.find((x) => x === AppPaymentType.CreditCard) != undefined;

  const displayCashPayment: boolean = acceptedPaymentTypes?.find((x) => x === AppPaymentType.Cash) !== undefined || basketSummary.amount === 0;

  useEffect(() => {
    setSinglePaymentType(calculateSinglePaymentType());
    console.log('Calculated single payment' + singlePaymentType);
  }, [displayCreditCardPayment, displayCashPayment]);

  const calculateSinglePaymentType = () => {
    if (displayCashPayment && !displayCreditCardPayment) {
      return PaymentType.CASH;
    } else if (displayCreditCardPayment && !displayCashPayment) {
      return PaymentType.CREDIT_CARD;
    }
    return null;
  };

  return [singlePaymentType, displayCashPayment, displayCreditCardPayment];
};

export const useProcessPayment = (paymentType: PaymentType | null): readonly [(ticketId?: string | null) => Promise<void>, boolean, PaymentError] => {
  const [paymentError, setPaymentError] = useState<PaymentError>({ severity: 'success' } as PaymentError);
  const [procesing, setProcesing] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const basketSummary = useAppSelector((state) => getBasketSummary(state.basket));
  const invoiceData = useAppSelector((state) => getInvoiceData(state.basket));
  const basketTickets = useAppSelector((state) => getBasketTickets(state.basket));

  const process = async (ticketId?: string | null) => {
    if (procesing) {
      return;
    }
    if (paymentType !== null) {
      setProcesing(true);
      const requestTickets = MapToRequestTicket(basketTickets);
      const createPaymentRequest = { tickets: requestTickets, invoiceData: invoiceData } as CreatePaymentRequest;
      try {
        if (!ticketId) {
          ticketId = searchParams.get('ticketId');
        }
        let orderStatus = {} as OrderStatus;
        if (ticketId) {
          orderStatus = await dispatch(getOrder(ticketId)).unwrap();
        } else {
          orderStatus = await dispatch(startPayment(createPaymentRequest)).unwrap();
        }

        switch (paymentType) {
          case PaymentType.CASH:
            var result = await dispatch(confirmPayment(CreateConfirmPaymentRequestModel(orderStatus, paymentType, basketSummary.amount, null))).unwrap();
            if (result) {
              console.log('Start printing. orderid' + orderStatus.orderId);
              const printState = printInvoice(orderStatus.orderId ?? '', orderStatus?.tickets ?? [], invoiceData?.nip);
              console.log('Print status: ' + printState);
              navigate('/payment_wizard/cash');
            } else {
              throw new Error('Cannot start payment');
            }
            break;
          case PaymentType.CREDIT_CARD:
            navigate('/payment_wizard/terminal');
            break;
          default:
            throw new Error('Cannot handle payment type: ' + paymentType);
        }
      } catch (e) {
        console.error('[PaymentWizard]', JSON.stringify(e));
        setProcesing(false);
        setPaymentError({ message: 'Wystąpił problem z rozpoczęciem płatności', severity: 'error' } as PaymentError);
      }
    } else {
      setPaymentError({ message: 'Wybierz formę płatności', severity: 'warning' } as PaymentError);
    }
  };

  return [process, procesing, paymentError];
};

interface PaymentError {
  message: string;
  severity: AlertColor;
}
