import { Grid, Stack, styled } from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { AddCompanyDataButton, PaymentButton, ReturnButton, SummaryText } from './styled-components';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { toWithReturnParam, getReturnUrl } from '../utils/navigationHelper';
import useDisplayToolbarReturnButton from '../layout/layoutHooks';
import { useTranslation } from 'react-i18next';

const PlusIcon = styled(AddCircleIcon)({
  // fontSize: '2rem',
  width: '35px',
  height: '35px',
  // '&>:nth-of-type(1)': {
  //   fontSize: '2rem',
  // },
});

interface PaymentWrapperProps {
  children: JSX.Element;
  title: string;
  onPaymentClick: () => void;
  displayCompanyDetailsButton?: boolean;
}

const PaymentWrapper = (props: PaymentWrapperProps) => {
  const { t } = useTranslation(['common']);
  const navigate = useNavigate();
  const location = useLocation();
  let [searchParams] = useSearchParams();

  const handlePaymentClick = () => {
    props.onPaymentClick();
  };

  const handleAddCompanyData = () => {
    navigate(toWithReturnParam(location, '/summary/company-details'));
  };

  const displayToolbarReturnButton = useDisplayToolbarReturnButton();

  return (
    <>
      <Stack direction="column" sx={{ my: 4 }}>
        {!displayToolbarReturnButton && (
          <ReturnButton onClick={() => navigate(getReturnUrl(searchParams, '/summary'))} sx={{ mb: 4 }}>
            <>{t('common:buttons.back')}</>
          </ReturnButton>
        )}
        <SummaryText sx={{ mb: 2 }}>{props.title}</SummaryText>
        {props.children}
        <Grid container sx={{ my: 2 }} spacing={2}>
          {props.displayCompanyDetailsButton && (
            <Grid item xs={6} sx={{ justifyContent: 'center' }}>
              <AddCompanyDataButton onClick={handleAddCompanyData} sx={{ alignSelf: 'center', width: 4 / 4 }} startIcon={<PlusIcon viewBox="0 0 24 24" />}>
                <>{t('common:summary.companyData')}</>
              </AddCompanyDataButton>
            </Grid>
          )}
          <Grid item container xs={props.displayCompanyDetailsButton ? 6 : 12} sx={{ justifyContent: 'center' }}>
            <PaymentButton onClick={handlePaymentClick} sx={{ width: (props.displayCompanyDetailsButton ? 4 : 2) / 4 }}>
              <>{t('common:buttons.pay')}</>
            </PaymentButton>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default PaymentWrapper;
