import styled from '@emotion/styled';
import { Box, ButtonBase, Paper, Stack } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const TicketBox2 = styled(ButtonBase)({ borderRadius: '20px', fontWeight: 500, color: 'white', fontSize: '30px', filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))' });
const WelcomeText = () => {
  return <Box component={motion.img} transition={{ duration: 4, repeat: Infinity, repeatDelay: 2 }} animate={{ x: [0, 10, -10, 0] }} src="/images/pole/witamy.png" sx={{ position: 'relative', left: '250px', top: '90px', height: '50px' }}></Box>;
};

const Home = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/tickets');
  };

  return (
    <>
      <Grid2 container spacing={3} sx={{ py: 3, height: '100%', backgroundImage: `url(${'/images/pole/mountain.png'})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom right', backgroundSize: '300px' }}>
        <Grid2 xs={6} sx={{ backgroundImage: `url(${'/images/pole/tourist.png'})`, backgroundRepeat: 'no-repeat', backgroundSize: '500px', backgroundPosition: 'center' }}>
          <WelcomeText></WelcomeText>
        </Grid2>
        <Grid2 xs={6} display="flex" justifyContent="center" alignItems="center">
          <Paper sx={{ width: '100%', py: 5, px: 3, boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
            <Stack direction="column" spacing={5}>
              <TicketBox2 sx={{ backgroundColor: '#4398D1' }} onClick={handleButtonClick}>
                <Grid2 container sx={{ height: '120px', p: 0, m: 0, width: 4 / 4 }}>
                  <Grid2 xs={4} sx={{ pl: 5, pb: 0 }} display="flex" alignItems="flex-end" justifyContent="center">
                    <Box component="img" src="/images/pole/ticket_icon.png" sx={{ height: '90px' }}></Box>
                  </Grid2>
                  <Grid2 xs={8} display="flex" justifyContent="left" alignItems="center">
                    <motion.div transition={{ duration: 2, repeat: Infinity, repeatDelay: 3, type: 'spring' }} animate={{ opacity: [1, 0.5, 1] }}>
                      Pobierz bilet
                    </motion.div>
                  </Grid2>
                </Grid2>
              </TicketBox2>
              <TicketBox2 sx={{ backgroundColor: '#EE4A83' }} onClick={() => navigate('/scan')}>
                <Grid2 container sx={{ height: '120px', p: 0, m: 0, width: 4 / 4 }}>
                  <Grid2 xs={4} sx={{ pl: 5, pb: 0 }} display="flex" alignItems="flex-end" justifyContent="center">
                    <Box component="img" src="/images/pole/ticket_scan_icon.png" sx={{ height: '90px' }}></Box>
                  </Grid2>
                  <Grid2 xs={8} display="flex" justifyContent="left" alignItems="center">
                    <motion.div transition={{ duration: 2, repeat: Infinity, repeatDelay: 3, type: 'spring' }} animate={{ opacity: [1, 0.5, 1] }}>
                      Zeskanuj swój bilet
                    </motion.div>
                  </Grid2>
                </Grid2>
              </TicketBox2>
            </Stack>
          </Paper>
        </Grid2>
      </Grid2>
    </>
  );
};

export default Home;
