import { StatModel, Ticket, TicketStateModel, TicketType } from './models';

export const getTicets = (state: TicketStateModel) => {
  return state.tickets;
};

export const getReducedTicet = (state: TicketStateModel): Ticket | undefined => {
  return state.tickets.find((x) => x.type === TicketType.REDUCED);
};

export const getNormalTicet = (state: TicketStateModel): Ticket | undefined => {
  return state.tickets.find((x) => x.type === TicketType.NORMAL);
};

export const getFreeTicets = (state: TicketStateModel): Array<Ticket> => {
  return state.tickets.filter((x) => x.type === TicketType.FREE);
};

export const getKDRTicet = (state: TicketStateModel): Ticket | undefined => {
  return state.tickets.find((x) => x.variantId === 146445);
};

export const getChildTicet = (state: TicketStateModel): Ticket | undefined => {
  return state.tickets.find((x) => x.variantId === 146444);
};

export const getParkingTickets = (state: TicketStateModel): Ticket[] | undefined => {
  return state.tickets.filter((x) => x.type === TicketType.PARKING);
};

export const getOtherFreeTickets = (state: TicketStateModel): Ticket[] => {
  const tickets = state.tickets.filter((x) => x.type === TicketType.FREE && x.variantId !== 146444 && x.variantId !== 146445);
  return tickets ?? ([] as Ticket[]);
};

export const hasFreeTickets = (state: TicketStateModel): boolean => {
  const freeTickets = state.tickets.findIndex((x) => x.type === TicketType.FREE);
  return freeTickets !== -1;
};

export const getStats = (state: TicketStateModel): Array<StatModel> => {
  return state.stats;
};

export const getTodayStats = (state: TicketStateModel): StatModel | null => {
  return state.stats.length > 0 ? state.stats[0] : null;
};
