import { BasketTicket } from '../basket/models';
import { ConfirmPaymentRequest, OrderStatus, PaymentType, RequestTicket } from './models';

export const MapToRequestTicket = (basketTickets: BasketTicket[]): RequestTicket[] => {
  return basketTickets.map((b) => {
    return {
      quantity: b.count,
      variantId: b.ticket.variantId,
      question: b.question,
    } as RequestTicket;
  });
};

export const CreateConfirmPaymentRequestModel = (paymentStatus: OrderStatus, paymentType: PaymentType, amount: number, transactionId: string | null): ConfirmPaymentRequest => {
  return {
    orderId: paymentStatus.orderId,
    paymentType: paymentType,
    amount: amount,
    transactionId: transactionId,
  } as ConfirmPaymentRequest;
};
