import { Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { styled, SxProps, Theme } from '@mui/material/styles';

interface BergRouterButtonProps {
  children: string;
  to: string;
  sx?: SxProps<Theme>;
  disabled?: boolean;
}

export const BergLgRouterButton = ({ children, to, sx }: BergRouterButtonProps) => {
  return (
    <Button component={RouterLink} color="secondary" to={to} variant="contained" sx={{ height: '93px', width: '347px', borderRadius: '50px', color: 'white', fontSize: '30px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', ...sx }}>
      {children}
    </Button>
  );
};

interface BergButtonProps {
  children: JSX.Element;
  onClick(): Promise<void>;
  sx?: SxProps<Theme>;
  disabled?: boolean;
}
export const BergLgButton = ({ children, onClick, sx, disabled }: BergButtonProps) => {
  return (
    <MainButton disabled={disabled} onClick={onClick} color="secondary" variant="contained" sx={{ ...sx }}>
      {children}
    </MainButton>
  );
};

export const BergMidButton = ({ children, to, sx, disabled }: BergRouterButtonProps) => {
  return (
    <Button disabled={disabled} component={RouterLink} to={to} variant="contained" sx={{ width: '250px', height: '67px', fontSize: '25px', fontWeight: '500', borderRadius: '50px', color: 'white', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', ...sx }}>
      {children}
    </Button>
  );
};

const MainButton = styled(Button)(({ theme }) => ({
  borderRadius: '50px',
  color: 'white',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  [theme.breakpoints.down('xl')]: {
    height: '75px',
    width: '280px',
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('xl')]: {
    height: '93px',
    width: '347px',
    fontSize: '1.875rem',
  },
}));

const SecondaryButton = styled(Button)(({ theme }) => ({
  borderRadius: '50px',
  color: 'white',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  [theme.breakpoints.down('xl')]: {
    height: '48px',
    width: '180px',
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('xl')]: {
    height: '67px',
    width: '250px',
    fontSize: '1.875rem',
  },
}));

export const EloMainButton = styled(MainButton)(({ theme }) => ({
  backgroundColor: '#9464FC',
  '&:hover': {
    backgroundColor: '#7650c9',
  },
}));

export const EloSecondaryButton = styled(SecondaryButton)(({ theme }) => ({
  backgroundColor: '#9464FC',
  '&:hover': {
    backgroundColor: '#7650c9',
  },
}));

export const BackButton = styled(Button)({});
