import { Box, Paper } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';
import { ReturnButton } from '../../components/styled-components';

interface CenteredBoxProps {
  children: JSX.Element;
  onClick: () => void;
}

const CenteredBox = ({ children, onClick }: CenteredBoxProps) => {
  const { t } = useTranslation(['common']);
  return (
    <Grid2 container height={4 / 4}>
      <Grid2 xs={3} paddingTop={4}>
        <ReturnButton onClick={onClick}>
          <>{t('common:buttons.back')}</>
        </ReturnButton>
      </Grid2>
      <Grid2 xs={6}>
        <Box paddingTop={4} alignContent="center" justifyContent="center" height="100%">
          <Paper sx={{ marginLeft: 'auto', marginRight: 'auto', height: 'calc(100% - 3vw)' }}>{children}</Paper>
        </Box>
      </Grid2>
    </Grid2>
  );
};

export default CenteredBox;
