import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';
import { getAppDetails } from '../../utils/terminal';
import actionTypes from './actionTypes';
import { ConfirmPaymentRequest, CreatePaymentRequest, OrderStatus } from './models';

export const startPayment = createAsyncThunk(actionTypes.START_PAYMENT, async (createPaymentRequest: CreatePaymentRequest): Promise<OrderStatus> => {
  try {
    const data = JSON.stringify(createPaymentRequest);
    console.log('startPayment: ' + data);
    //TODO: take app id from API
    const appDetails = await getAppDetails();
    var response = await axiosInstance.post<OrderStatus>(`/v1/paybox/${appDetails.id}/tickets`, data);
    return response.data;
  } catch (e) {
    console.error(JSON.stringify(e));
    throw e;
  }
});

export const getOrder = createAsyncThunk(actionTypes.GET_ORDER, async (ticketId: string): Promise<OrderStatus> => {
  try {
    const appDetails = await getAppDetails();
    var response = await axiosInstance.get<OrderStatus>(`/v1/paybox/${appDetails.id}/tickets/${ticketId}/order`);
    return response.data;
  } catch (e) {
    console.error(JSON.stringify(e));
    throw e;
  }
});

export const confirmPayment = createAsyncThunk(actionTypes.CONFIRM_PAYMENT, async (createPaymentRequest: ConfirmPaymentRequest): Promise<boolean> => {
  try {
    const appDetails = await getAppDetails();
    const data = JSON.stringify(createPaymentRequest);
    await axiosInstance.post(`/v1/paybox/${appDetails.id}/tickets/pay`, data);
    return true;
  } catch (e) {
    console.error(JSON.stringify(e));
    return false;
  }
});

// export const startAndConfirmPayment = createAsyncThunk(actionTypes.START_AND_CONFIRM, async (requestTickets: RequestTicket[], thunkApi) => {
//   var result = await thunkApi.dispatch(startPayment(requestTickets));

//   await thunkApi.dispatch(confirmPayment(result.payload.orderId));
// });
