import { AnimatePresence } from 'framer-motion';
import { Route, Routes, useLocation } from 'react-router-dom';
import Layout from './layout/layout/Layout';
import Home from './pages/pole/Home';
import './i18n/i18n';
import { Wrapper } from './App';
import PoleTickets from './pages/pole/PoleTickets';
import ParkingSummary from './pages/ParkingSummary';
import ParkingPlate from './pages/ParkingPlate';
import PaymentWizard from './pages/PaymentWizard';
import Terminal from './pages/paymentWizzard/Terminal';
import Cash from './pages/paymentWizzard/Cash';
import Confirmation from './pages/paymentWizzard/Terminal/Confirmation';
import QRCode from './pages/QRCode';
import ScanPage from './pages/pole/Scan';
import PoleValidTicket from './pages/pole/tickets/PoleValidTicket';
import PoleInvalidTicket from './pages/pole/tickets/PoleInvalidTicket';
import SupportContact from './pages/pole/SupportContact';
import Generated from './pages/pole/tickets/Generated';

const AppPole = () => {
  const location = useLocation();
  return (
    <Layout>
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.key}>
          <Route element={<Wrapper></Wrapper>}>
            <Route path="/" element={<Home></Home>}></Route>
            <Route path="/tickets" element={<PoleTickets />}></Route>
            <Route path="vehicle" element={<ParkingPlate></ParkingPlate>}></Route>
            <Route path="vehicle/ticket/:plate" element={<ParkingSummary></ParkingSummary>}></Route>
            <Route path="payment_wizard" element={<PaymentWizard />}></Route>
            <Route path="payment_wizard/terminal" element={<Terminal />}></Route>
            <Route path="payment_wizard/cash" element={<Cash />}></Route>
            <Route path="payment_wizard/terminal/confirmation" element={<Confirmation />}></Route>
            <Route path="qrcodes" element={<QRCode />}></Route>
            <Route path="/scan" element={<ScanPage />}></Route>
            <Route path="/ticket/valid/:id" element={<PoleValidTicket />}></Route>
            <Route path="/ticket/invalid/:id" element={<PoleInvalidTicket />}></Route>
            <Route path="/ticket/generated" element={<Generated />}></Route>
            <Route path="/support" element={<SupportContact />}></Route>
          </Route>
        </Routes>
      </AnimatePresence>
    </Layout>
  );
};

export default AppPole;
