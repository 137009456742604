import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Slice } from '../../types';
import { StatModel, Ticket, TicketStateModel } from './models';
import { fetchStats, fetchTickets } from './operations';

const initialState = {
  tickets: [] as Ticket[],
  stats: [] as StatModel[],
} as TicketStateModel;

const ticktetSlice = createSlice({
  name: Slice.Tickets,
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchTickets.fulfilled, (state, action: PayloadAction<Ticket[]>) => {
      state.tickets = action.payload;
    });
    builder.addCase(fetchStats.fulfilled, (state, action: PayloadAction<StatModel[]>) => {
      // const date = new Date(action.payload[1].date);
      // console.log(date);
      // console.log(format(date, 'HH.mm'));
      const rows = action.payload;
      // rows.forEach((s) => (s.dateFrm = new Date(s.date)));
      state.stats = rows;
    });
  },
});

export const {} = ticktetSlice.actions;
export default ticktetSlice.reducer;
