import { Alert, AlertColor, Box, Divider, Snackbar, Stack, styled, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BatteryLevel from '../components/BatteryLevel';
import StatBox from '../components/StatBox';
import { ReturnButton } from '../components/styled-components';
import { getClientLayout } from '../modules/layout/selectors';
import { getStats } from '../modules/tickets/selectors';
import StatsDialog from '../pages/tickets/StatsDialog';
import { Layout } from '../setup/Layout';
import { useAppSelector } from '../utils/hooks';
import { getReturnUrl } from '../utils/navigationHelper';
import { getTerminal, pairTerminal } from '../utils/terminal';
import useDisplayToolbarReturnButton from './layoutHooks';
import LogoComponent from './LogoComponent';

export default function TopAppBar() {
  const { t } = useTranslation(['common']);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const stats = useAppSelector((x) => getStats(x.tickets));
  const layout = useAppSelector((x) => getClientLayout(x.layout));
  const displayToolbarReturnButton = useDisplayToolbarReturnButton();
  let pairingTaps = useRef<number>(0);

  const [open, setOpen] = useState<boolean>(false);
  const [openStatsDialog, setOpenStatsDialog] = useState<boolean>(false);
  const [type, setType] = useState<AlertColor>('success');
  const [message, setMessage] = useState<string>('');
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  setInterval(() => {
    pairingTaps.current = 0;
  }, 1000 * 60);

  const initPairing = async () => {
    pairingTaps.current++;
    if (pairingTaps.current === 6) {
      console.log('initPairing');
      const terminal = await getTerminal();
      pairingTaps.current = 0;
      pairTerminal()
        .then((response) => {
          console.log(response);
          switch (response) {
            case 'NO_CONNECTION':
              handleOpenSnackBar(`Nie znaleziono terminala pod adresem ${terminal.ip} i portem ${terminal.port}.`, 'error');
              break;
            case 'PAIRING_SUCCESSFUL':
              handleOpenSnackBar('Parowanie zakończone pomyślnie', 'success');
              break;

            default:
              handleOpenSnackBar('Parowanie nieudane. Skontaktuj się z obsługą', 'error');
              break;
          }
        })
        .catch((e) => {
          handleOpenSnackBar('Parowanie nieudane. Skontaktuj się z obsługą', 'error');
          console.error(JSON.stringify(e));
        });
    }
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleOpenSnackBar = (message: string, type: AlertColor) => {
    setMessage(message);
    setType(type);
    setOpen(true);
  };

  const handleClick = () => {
    initPairing();
  };

  const handleStatBoxClick = () => {
    setOpenStatsDialog(true);
  };

  const handleStatDialogCloseClick = () => {
    setOpenStatsDialog(false);
  };

  const handleReturnButtonClick = () => {
    navigate(getReturnUrl(searchParams, '/'));
  };

  return (
    <>
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      <AppBar sx={{ boxShadow: 'none' }}>
        <ResponsiveToolbar sx={{ backgroundColor: 'white' }}>
          <Stack direction="row" justifyContent="space-between" width="100%">
            {isSmallScreen && (
              <ReturnButton onClick={handleReturnButtonClick} sx={{ alignSelf: 'center', visibility: displayToolbarReturnButton ? 'visible' : 'hidden' }}>
                <>{t('common:buttons.back')}</>
              </ReturnButton>
            )}
            <LogoComponent openSnackBar={handleOpenSnackBar}></LogoComponent>
            <Stack direction="row" spacing={2} alignItems="center">
              {layout === Layout.Desktop && stats[0] ? <StatBox isFirst={true} statsModel={stats[0] ?? {}} onClick={() => handleStatBoxClick()}></StatBox> : <></>}
              {layout === Layout.Desktop && stats[1] ? <StatBox isFirst={false} statsModel={stats[1] ?? {}} onClick={() => handleStatBoxClick()}></StatBox> : <></>}
            </Stack>
            <Stack direction="row" spacing={2} alignItems="center">
              <BatteryLevel></BatteryLevel>

              {!isSmallScreen && (
                <>
                  <Divider orientation="vertical"></Divider>
                  <Box component="img" src="/images/bergregions.png" alt="BergRegions" height={50} onClick={() => handleClick()}></Box>
                </>
              )}
            </Stack>
          </Stack>
        </ResponsiveToolbar>
      </AppBar>
      <StatsDialog open={openStatsDialog} onClose={() => handleStatDialogCloseClick()} stats={stats}></StatsDialog>
    </>
  );
}

export const ResponsiveToolbar = styled(Toolbar)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    height: 63,
    '& .logo': {
      maxHeight: 63,
    },
  },
  [theme.breakpoints.up('md')]: {
    height: 80,
    '& .logo': {
      maxHeight: 80,
    },
  },
  [theme.breakpoints.up('lg')]: {
    height: 100,
    '& .logo': {
      maxHeight: 100,
    },
  },
  [theme.breakpoints.up('xl')]: {
    height: 128,
    '& .logo': {
      maxHeight: 100,
    },
  },
}));
