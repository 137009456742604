import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Slice } from '../../types';
import { InvoiceData, Ticket, TicketType } from '../tickets/models';
import { BasketStateModel, BasketTicket } from './models';

const initialState = {
  tickets: [] as BasketTicket[],
} as BasketStateModel;

const basketSlice = createSlice({
  name: Slice.Basket,
  initialState: initialState,
  reducers: {
    increment: (state, action: PayloadAction<Ticket>) => {
      const ticket = action.payload;
      const basketTicket = state.tickets.find((x) => x.ticket.variantId === ticket.variantId);
      if (basketTicket) {
        basketTicket.count++;
        basketTicket.ammount += ticket.price.amount;
      } else {
        const newBasketTicket = {
          ticket: ticket,
          ammount: ticket.price.amount,
          count: 1,
        } as BasketTicket;
        state.tickets.push(newBasketTicket);
      }
    },
    decrement: (state, action: PayloadAction<Ticket>) => {
      const ticket = action.payload;
      const basketTicket = state.tickets.find((x) => x.ticket.variantId === ticket.variantId);
      if (basketTicket) {
        basketTicket.count--;
        if (basketTicket.count > 0) {
          basketTicket.ammount -= ticket.price.amount;
        } else {
          state.tickets.splice(
            state.tickets.findIndex((x) => x.ticket.variantId === ticket.variantId),
            1
          );
        }
      }
    },
    addBasketTicket: (state, action: PayloadAction<BasketTicket>) => {
      const basketTicket = action.payload;
      if (basketTicket) {
        state.tickets.push(basketTicket);
      }
    },
    addOrUpdateBasketTicket: (state, action: PayloadAction<BasketTicket>) => {
      const basketTicket = action.payload;

      if (basketTicket) {
        const currentBasketTicketIndex = state.tickets.findIndex((x) => x.ticket.variantId === basketTicket.ticket.variantId);
        if (currentBasketTicketIndex >= 0) {
          state.tickets.splice(currentBasketTicketIndex, 1);
        }

        state.tickets.push(basketTicket);
      }
    },
    removeBasketTicket: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      state.tickets.splice(
        state.tickets.findIndex((x) => x.id === id),
        1
      );
    },
    deleteTicketGroup: (state, action: PayloadAction<TicketType>) => {
      const ticketType = action.payload;
      state.tickets = state.tickets.filter((x) => x.ticket.type !== ticketType);
    },
    clearBasket: (state) => {
      console.log('Clear basket');
      state.tickets = [];
      state.invoiceData = undefined;
    },
    addCompanyData: (state, action: PayloadAction<InvoiceData>) => {
      console.log(action.payload);
      state.invoiceData = action.payload;
    },
  },
});

export const { increment, decrement, deleteTicketGroup, clearBasket, addBasketTicket, addOrUpdateBasketTicket, removeBasketTicket, addCompanyData } = basketSlice.actions;
export default basketSlice.reducer;
