// export class ClientSetupBase {
//   constructor(public imgLogoUrl: string, public clientDescription: string) {}
// }

export interface ClientSetup {
  imgLogoUrl: string;
  clientDescription: string;
}

export const PngsClientSetup = {
  imgLogoUrl: '/images/logo_poziome2.png',
  clientDescription: 'Park Narodowy Gór Stołowych',
} as ClientSetup;

export const TpnClientSetup = {
  imgLogoUrl: '/images/tpn.png',
  clientDescription: 'Tatrzański Park Narodowy',
} as ClientSetup;

export const MesseClientSetup = {
  imgLogoUrl: '/images/messe.png',
  clientDescription: 'Messe Duesseldorf',
} as ClientSetup;
