export enum TicketColor {
  Normal = '#6260A8',
  Reduced = '#EE4A83',
  Free = '#FFB039',
}

export enum ButtonColor {
  Violet = '#9464FC',
  VioletHover = '#7650c9',
  Grey = '#5E5E5E',
  GreyHover = '#7e7e7e ',
}

export enum PayboxColors {
  Light3 = '#DFDFE6',
  Light = '#FCFCFD',
}
