import { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import PlateForm from '../components/PlateForm';
import { toWithReturnParam } from '../utils/navigationHelper';

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const ParkingPlate = () => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const location = useLocation();

  useEffect(() => {
    if (!searchParams.get('variantId')) {
      console.error(`Cannot get parking ticket for empty variantId`);
    }
  }, []);

  const handleConfirmClick = (plate: string) => {
    navigate(toWithReturnParam(location, `/vehicle/ticket/${plate}`));
  };

  return <PlateForm handleReturn={() => navigate(searchParams.get('r') ?? '/')} handleConfirm={handleConfirmClick} confirmButtonText="Dodaj"></PlateForm>;
};

export default ParkingPlate;
