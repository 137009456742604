import { Grid, Typography, Divider, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { GreenButton } from '../components/styled-components';
import { ResponseTicket } from '../modules/payment/models';
import { getPaymentState } from '../modules/payment/selectors';
import { clearPayment } from '../modules/payment/slice';
import { useAppDispatch, useAppReturnLink, useAppSelector } from '../utils/hooks';
import QrTicket from './qrcode/QrTicket';

const QRCode = () => {
  const { t } = useTranslation(['common']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const paymentState = useAppSelector((state) => getPaymentState(state.payment));
  const primaryLink = useAppReturnLink();

  const handleCloseClick = async () => {
    dispatch(clearPayment);
    navigate(primaryLink);
  };

  const getPairTickets = () => {
    const pairs = [] as any[];
    if (paymentState) {
      for (let index = 0; index < paymentState?.tickets?.length; index += 2) {
        const pair = [] as ResponseTicket[];
        pair.push(paymentState.tickets[index]);
        if (index + 1 < paymentState.tickets.length) {
          pair.push(paymentState.tickets[index + 1]);
        }
        pairs.push(pair);
      }
    }

    return pairs;
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} sx={{ py: 3 }}>
          <Typography sx={{ fontSize: '25px', color: '#4E4E4E' }}>{t('common:warnings.disconnectedPrinter').toString()}</Typography>
        </Grid>
        <Grid item xs={12} sx={{ mb: 3 }}>
          <Divider color="#949494"></Divider>
        </Grid>
        <Grid item xs={12} sx={{ mb: 3 }}>
          <Typography sx={{ fontSize: '20px', color: '#4E4E4E', fontWeight: '700' }}>
            {t('common:qrCodes.orderNumber').toString()}: {paymentState?.orderId}
          </Typography>
        </Grid>
      </Grid>

      <Grid container sx={{ bottom: '0px' }}>
        <Grid item xs={12}>
          {getPairTickets().map((t: ResponseTicket[]) => {
            if (t.length > 1) {
              return (
                <Stack direction="row" spacing={5} sx={{ justifyContent: 'center', mb: 4 }}>
                  <QrTicket name={t[0].name} qrCode={t[0].qrCode}></QrTicket>
                  <QrTicket name={t[1].name} qrCode={t[1].qrCode}></QrTicket>
                </Stack>
              );
            }
            return (
              <Stack direction="row" spacing={5} sx={{ justifyContent: 'center' }}>
                <QrTicket name={t[0].name} qrCode={t[0].qrCode}></QrTicket>
              </Stack>
            );
          })}
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 4 }}>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <GreenButton onClick={handleCloseClick}>
            <>{t('common:buttons.complete')}</>
          </GreenButton>
        </Grid>
      </Grid>

    </>
  );
};
export default QRCode;
