import { Box, Stack, styled, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GreenButton, ScanNextTicketButton, VioletButton } from '../../components/styled-components';
import { TicketDetails } from '../../modules/tickets/models';
import { getTicketDetails } from '../../modules/tickets/operations';
import AnswerEditor from './AnswerEditor';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';

export const HeaderText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '1.125rem',
  },
  fontWeight: 700,
  fontSize: '1.9rem',
  color: '#2B2A2A',
  fontFamily: 'Poppins',
  textAlign: 'center',
}));
export const HeaderColumnText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '0.875rem',
  },
  fontWeight: 400,
  fontSize: '1.5rem',
  color: '#2B2A2A',
  fontFamily: 'Poppins',
}));
export const ValueColumnText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '0.875rem',
  },
  fontWeight: 700,
  fontSize: '1.5rem',
  color: '#2B2A2A',
  fontFamily: 'Poppins',
}));

export const getFormatedDate = (dateStr: string | undefined): string => {
  if (dateStr) {
    const date = new Date(dateStr);
    return format(date, 'dd.MM.yyyy HH:mm');
  }
  return '';
};

const ValidTicket = () => {
  const { t } = useTranslation(['common']);
  const { id } = useParams();
  const navigate = useNavigate();
  const [ticket, setTicket] = useState<TicketDetails | null>(null);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  useEffect(() => {
    const GetTicket = async () => {
      if (id) {
        const td = await getTicketDetails(id);
        console.log(td);
        setTicket(td);
      }
    };
    GetTicket();
  }, [id]);

  return (
    <>
      <Grid container sx={{ mt: 2 }}>
        <Grid lgOffset={3} lg={6} xs={12} sx={{ backgroundColor: 'white', px: 6, pb: 2 }}>
          <Stack direction="column" sx={{ width: '100%', alignItems: 'center', mt: 4 }} spacing={4}>
            <HeaderText>
              <>{t('common:ticketStatus.validTicketHeader')}</>
            </HeaderText>
            <Box component="img" src="/images/valid.png"></Box>
            <Box sx={{ alignItems: 'left' }}>
              <Stack direction="row" spacing={2}>
                <HeaderColumnText>
                  <>{t('common:ticketStatus.ticketIdHeader')}</>:
                </HeaderColumnText>
                <ValueColumnText>{ticket?.ticketId}</ValueColumnText>
              </Stack>
              {ticket?.question != null && (
                <Stack direction="row" spacing={2}>
                  <HeaderColumnText>{ticket?.question?.question}:</HeaderColumnText>
                  <ValueColumnText>{ticket?.question?.answer}</ValueColumnText>
                </Stack>
              )}
              <Stack direction="row" spacing={2}>
                <HeaderColumnText>
                  <>{t('common:ticketStatus.ticketTypeHeader')}</>:
                </HeaderColumnText>
                <ValueColumnText>{ticket?.variantName}</ValueColumnText>
              </Stack>
              <Stack direction="row" spacing={2}>
                <HeaderColumnText>
                  <>{t('common:ticketStatus.validSinceHeader')}</>:
                </HeaderColumnText>
                <ValueColumnText>{getFormatedDate(ticket?.validSince)}</ValueColumnText>
              </Stack>
              <Stack direction="row" spacing={2}>
                <HeaderColumnText>
                  <>{t('common:ticketStatus.validUntilHeader')}</>:
                </HeaderColumnText>
                <ValueColumnText>{getFormatedDate(ticket?.validUntil)}</ValueColumnText>
              </Stack>
              <Stack direction="row" spacing={2}>
                <HeaderColumnText>
                  <>{t('common:ticketStatus.ticketPriceHeder')}</>:
                </HeaderColumnText>
                <ValueColumnText>
                  {ticket?.price} {ticket?.currency}
                </ValueColumnText>
              </Stack>
            </Box>
            <Stack direction="row" spacing={1}>
              <GreenButton onClick={() => navigate('/')}>
                <>{t('common:buttons.complete')}</>
              </GreenButton>
              {ticket?.question != null && (
                <VioletButton onClick={() => setOpenDialog(true)}>
                  <>{t('common:ticketStatus.changeAnswer')}</>
                </VioletButton>
              )}
            </Stack>
            <ScanNextTicketButton onClick={() => navigate('/scan')}>
              <>{t('common:ticketStatus.scanNext')}</>
            </ScanNextTicketButton>
          </Stack>
        </Grid>
      </Grid>
      <AnswerEditor handleClose={() => setOpenDialog(false)} open={openDialog} ticket={ticket}></AnswerEditor>
    </>
  );
};

export default ValidTicket;
