import { AppDetails } from './device';

export const AppInfoKey = 'AppDetails';
export const AppDetailsExpireOn = 'AppDetailsExpireOn';
export const Macs = {
  PngsWithPrinter: '1C:EE:C9:14:79:8F',
  TpnSample: '02:00:00:00:00:00',
} as const;

export const SampleTpnAppDetails = {
  id: 50779937,
  name: 'TPN Parking PayBox - test',
  appType: 'DROPLABS_TICKET',
  language: 'en',
  languageVersions: ['en', 'fr', 'de', 'pl'],
  client: 'tpn',
  layout: 'mobile',
  enabledFeatures: ['ticketSale', 'ticketSearchByCarPlates', 'ticketScan'],
  primaryMode: 'sales',
  devices: [
    {
      id: 52039571,
      name: 'Drukarka PosNet Temo online',
      type: 'POSNET_FISCAL_PRINTER',
      function: 'FISCAL_PRINTER',
      ip: '192.168.100.121',
      port: '1234',
    },
    {
      id: 52039572,
      name: 'SmartPos Android app',
      type: 'PAYTEL_SMART_POS_PAYMENT_TERMINAL',
      function: 'PAYMENT_TERMINAL',
      ip: 'localhost',
      port: null,
    },
  ],
  hotspotRequired: false,
  fiscalPrinterRequired: true,
  embeddedQrScanner: true,
  embeddedLprScanner: true,
  acceptedPaymentTypes: ['credit_card'],
} as AppDetails;

export const SamplePNGSAppDetails = {
  id: 49164327,
  name: 'Test app for Droplabs Tickets',
  appType: 'DROPLABS_TICKET',
  language: 'en',
  languageVersions: ['en', 'fr', 'de', 'pl'],
  client: 'messe',
  layout: 'mobile',
  enabledFeatures: ['ticketScan', 'ticketSale'],
  primaryMode: 'sales',
  embeddedLprScanner: true,
  devices: [
    {
      id: 46677196,
      name: 'Paytel Terminal',
      type: 'PAYMENT_TERMINAL',
      function: 'PAYMENT_TERMINAL',
      ip: '192.168.100.23',
      port: '5002',
    },
  ],
  hotspotRequired: false,
  fiscalPrinterRequired: true,
  acceptedPaymentTypes: ['credit_card', 'cash'],
  paymentLogo: 'vivaWallet',
} as AppDetails;
