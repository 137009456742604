import { TerminalState } from './modules/payment/const';

export const enum Slice {
  Tickets = 'Tickets',
  Basket = 'Basket',
  Payment = 'Payment',
  Layout = 'Layout',
}

declare global {
  interface Window {
    BtDevice: any;
    BtApp: any;
  }
}

interface BtDevice {
  printInvoice(type: string, ip: string, port: string, orderId: string, tickets: string);
  pairTerminal(ip: string, port: string);
  startPayment(ip: string, port: string, amount: number, orderId: string): TerminalState;
  getDeviceMac(): string;
  generateQR(qrCode: string): string;
  isHotspotEnabled(): boolean;
  openHotspotSettings(): void;
  getBatteryLevel(): number;
  handleQrReader(data: string): void;
}
