import { Button, styled, Typography, Alert } from '@mui/material';

import { ButtonColor } from '../layout/colors';

export const SummaryText = styled(Typography)({ fontSize: '1.8rem', color: '#5E5E5E', px: 4 });

export const PaymentButton = styled(Button)(({ theme }) => ({
  // [theme.breakpoints.up('md')]: {  },
  [theme.breakpoints.down('md')]: { width: '100%' },
  backgroundColor: '#FF4242',
  color: 'white',
  fontSize: '1.5rem',
  fontWeight: 700,
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: '3rem',
  fontFamily: 'Roboto, sans-serif',
  '&:hover': {
    backgroundColor: '#ff6767',
  },
  width: '30vw',
  height: '5rem',
}));

export const AddCompanyDataButton = styled(PaymentButton)({
  backgroundColor: ButtonColor.Violet,
  width: '60%',
  '&:hover': {
    backgroundColor: ButtonColor.VioletHover,
  },
});

export const MiddleWidthButton = styled(PaymentButton)({
  backgroundColor: ButtonColor.Violet,
  width: '70%',
  '&:hover': {
    backgroundColor: ButtonColor.VioletHover,
  },
});

const BaseReturnButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.72rem',
    fontWeight: 500,
    borderRadius: '1rem',
    width: '5rem',
    height: '2rem',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.5rem',
    fontWeight: 700,
    borderRadius: '3rem',
    width: '210px',
    height: '69px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.5rem',
    fontWeight: 700,
    borderRadius: '3rem',
    width: '13rem',
    height: '5rem',
  },
  color: 'white',
  px: 4,
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  fontFamily: 'Roboto, sans-serif',
}));

export const ReturnButton = styled(BaseReturnButton)(({ theme }) => ({
  backgroundColor: ButtonColor.Violet,
  color: 'white',
  '&:hover': {
    backgroundColor: ButtonColor.VioletHover,
  },
}));

export const WhiteReturnButton = styled(BaseReturnButton)({
  backgroundColor: 'white',
  color: '#6260A8',
});

const BaseButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    minWidth: '20vw',
    height: '6vh',
  },
  [theme.breakpoints.up('lg')]: {
    minWidth: '20vw',
    height: '8vh',
  },
  [theme.breakpoints.down('sm')]: {
    height: 75,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    fontSize: '1.125rem',
  },
  color: 'white',
  fontSize: '1.5rem',
  fontWeight: 700,
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: '3rem',
  fontFamily: 'Roboto, sans-serif',
}));

export const GreyButton = styled(BaseButton)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    minWidth: 250,
    height: 67,
    fontSize: '1.125rem',
  },
  [theme.breakpoints.down('lg')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    height: 75,
  },
  backgroundColor: ButtonColor.Grey,
  color: 'white',
  '&:hover': {
    backgroundColor: ButtonColor.GreyHover,
  },
}));

export const GreenButton = styled(BaseButton)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    minWidth: 250,
    height: 67,
    fontSize: '1.125rem',
  },
  [theme.breakpoints.down('lg')]: {
    height: 75,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  backgroundColor: '#32BA7C',
  color: 'white',
  '&:hover': {
    backgroundColor: '#5BC796',
  },
}));

export const VioletButton = styled(BaseButton)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    height: 67,
    fontSize: '1.125rem',
  },
  backgroundColor: ButtonColor.Violet,
  color: 'white',
  '&:hover': {
    backgroundColor: ButtonColor.VioletHover,
  },
}));

export const ScanNextTicketButton = styled(VioletButton)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    minWidth: 290,
    height: 67,
  },
  [theme.breakpoints.down('sm')]: {
    height: 75,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  fontSize: '1.125rem',
}));

export const RedButton = styled(BaseButton)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    height: 67,
  },
  fontSize: '1.125rem',
  backgroundColor: '#FF4242',
  color: 'white',
  '&:hover': {
    backgroundColor: '#ff6767',
  },
}));

export const BergAlert = styled(Alert)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '1.5em',
  },
}));
