import { Grid, useTheme, useMediaQuery } from '@mui/material';
import PaymentStepper from '../../components/PaymentStepper';
import { ReturnButton } from '../../components/styled-components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getReturnUrl } from '../../utils/navigationHelper';
import useDisplayToolbarReturnButton from '../../layout/layoutHooks';
import { useTranslation } from 'react-i18next';
import PaymentTypeBox from "../../components/PaymentTypeBox";
import {useAppSelector} from "../../utils/hooks";
import {getPaymentProvider} from "../../modules/layout/selectors";

interface WizardStepsProps {
  children: JSX.Element;
  activeStep: number;
  disableBackButton?: boolean;
}

const WizardSteps = ({ children, activeStep, disableBackButton }: WizardStepsProps) => {
  const { t } = useTranslation(['common']);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const isDownMDScreen = useMediaQuery(theme.breakpoints.down('md'));
  const displayToolbarReturnButton = useDisplayToolbarReturnButton();
  const paymentProvider = useAppSelector(x => getPaymentProvider(x.layout));

  return (
    <>
      <Grid container sx={{ mt: 2 }}>
        {!displayToolbarReturnButton && (
          <Grid item xs={12} lg={3}>
            {activeStep === 0 && (
              <ReturnButton onClick={() => navigate(getReturnUrl(searchParams, '/summary'))} disabled={disableBackButton}>
                <>{t('common:buttons.back')}</>
              </ReturnButton>
            )}
          </Grid>
        )}
        <Grid item xs={12} lg={6} sx={{ backgroundColor: 'white', py: 3, px: 6 }}>
          {!isDownMDScreen && <PaymentStepper activeStep={activeStep}></PaymentStepper>}
          {children}
          <PaymentTypeBox paymentType={paymentProvider} />
        </Grid>
      </Grid>
    </>
  );
};

export default WizardSteps;
