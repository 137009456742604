import { Divider, Grid, Stack, styled, Typography, TextField, Snackbar } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BergAlert, GreenButton, MiddleWidthButton, ReturnButton } from '../../../components/styled-components';
import { PayboxColors } from '../../../layout/colors';
import useDisplayToolbarReturnButton from '../../../layout/layoutHooks';
import { getInvoiceData } from '../../../modules/basket/selectors';
import { addCompanyData } from '../../../modules/basket/slice';
import { Address, InvoiceData } from '../../../modules/tickets/models';
import { getInvoiceDataFromKrs } from '../../../modules/tickets/operations';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks';
import { getReturnUrl } from '../../../utils/navigationHelper';

class InputStatus {
  constructor(public message: string, public isValid: boolean) {}
}

const Label = styled(Typography)({
  fontSize: '1.2rem',
  lineHeight: '2.4rem',
  color: '#4E4E4E',
});

const Input = styled(TextField)({
  borderRadius: '0.8rem',
  variant: 'outlined',
  '& .MuiFormHelperText-root': { fontSize: '1rem' },
  '& input': {
    border: `solid ${PayboxColors.Light3} 2px`,
    height: '4rem',
    borderRadius: '0.8rem',
    backgroundColor: PayboxColors.Light,
    fontSize: '2rem',
  },
  '& fieldset': {
    borderRadius: '0.8rem',
  },
});

const Search = () => {
  const { t } = useTranslation(['common']);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [nip, setNip] = useState<string>('');
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [invoiceData, setInvoiceData] = useState<InvoiceData>({ address: {} as Address } as InvoiceData);
  const basketInvoiceData = useAppSelector((x) => getInvoiceData(x.basket));
  const [inputStatuses, setInputStatuses] = useState<{ [key: string]: InputStatus }>({});
  const [saveClicked, setSaveClicked] = useState<boolean>(false);
  const [openError, setoOpenError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const displayToolbarReturnButton = useDisplayToolbarReturnButton();

  const handleSave = () => {
    if (isValid()) {
      dispatch(addCompanyData(invoiceData));

      navigate(getReturnUrl(searchParams, '/summary'));
    } else {
      setErrorMessage('Uzupełnij brakujace pola.');
      setoOpenError(true);
    }
    setSaveClicked(true);
  };

  const isValid = (): boolean => {
    if (!saveClicked) {
      ValidateInputs();
    }
    if ((inputStatuses.nip?.isValid ?? false) && (inputStatuses.name?.isValid ?? false) && (inputStatuses.streetAndNumber?.isValid ?? false) && (inputStatuses.city?.isValid ?? false) && (inputStatuses.postalCode?.isValid ?? false)) {
      return true;
    }
    return false;
  };

  const ValidateInputs = (): void => {
    const newInputStatuses = { ...inputStatuses };
    if (invoiceData.nip.length === 0) {
      newInputStatuses.nip.isValid = false;
      newInputStatuses.nip.message = t('common:summary.requiredField');
    }
    if (invoiceData.name === undefined || invoiceData.name.length === 0) {
      newInputStatuses.name.isValid = false;
      newInputStatuses.name.message = t('common:summary.requiredField');
    }
    if (invoiceData.address.streetAndNumber === undefined || invoiceData.address.streetAndNumber.length === 0) {
      newInputStatuses.streetAndNumber.isValid = false;
      newInputStatuses.streetAndNumber.message = t('common:summary.requiredField');
    }
    if (invoiceData.address.city === undefined || invoiceData.address.city.length === 0) {
      newInputStatuses.city.isValid = false;
      newInputStatuses.city.message = t('common:summary.requiredField');
    }
    if (invoiceData.address.postalCode === undefined || invoiceData.address.postalCode.length === 0) {
      newInputStatuses.postalCode.isValid = false;
      newInputStatuses.postalCode.message = t('common:summary.requiredField');
    }
    setInputStatuses(newInputStatuses);
  };

  const handleGetKRSData = async () => {
    if (nip.length > 0) {
      const invd = await getInvoiceDataFromKrs(nip);
      if (invd) {
        setInvoiceData(invd);
      }

      setIsEditMode(true);
    } else {
      setErrorMessage(t('common:summary.requiredNip'));
      setoOpenError(true);
    }
  };
  const handleInvoiceDataChange = (e) => {
    if (e.name === 'name') {
      SetInputStatus(e);
    }
    const newInvoiceData = { ...invoiceData };
    newInvoiceData[e.name] = e.value;
    setInvoiceData(newInvoiceData);
  };
  const handleInvoiceAddressChange = (e) => {
    SetInputStatus(e);
    const newInvoiceData = { ...invoiceData };
    newInvoiceData.address[e.name] = e.value;
    setInvoiceData(newInvoiceData);
  };

  const handleNipChange = (e) => {
    SetInputStatus(e);
    setInvoiceData({ ...invoiceData, nip: e.value });
    setNip(e.value);
  };

  const CreateInputStatusIfNotExist = (inputName: string): { [key: string]: InputStatus } => {
    if (inputStatuses[inputName]) {
      return { ...inputStatuses };
    }
    const newInputStatuses = { ...inputStatuses };
    newInputStatuses[inputName] = { message: '', isValid: false } as InputStatus;
    return newInputStatuses;
  };

  const SetInputStatus = (e) => {
    if (!saveClicked) {
      return;
    }
    const newInputStatuses = CreateInputStatusIfNotExist(e.name);
    if (e.value.length > 0) {
      newInputStatuses[e.name].isValid = true;
      newInputStatuses[e.name].message = '';
    } else {
      newInputStatuses[e.name].isValid = false;
      newInputStatuses[e.name].message = t('common:summary.requiredField');
    }

    setInputStatuses(newInputStatuses);
  };

  useEffect(() => {
    const newInputStatuses: { [key: string]: InputStatus } = {};
    newInputStatuses.name = { message: '', isValid: true } as InputStatus;
    newInputStatuses.nip = { message: '', isValid: true } as InputStatus;
    newInputStatuses.postalCode = { message: '', isValid: true } as InputStatus;
    newInputStatuses.streetAndNumber = { message: '', isValid: true } as InputStatus;
    newInputStatuses.city = { message: '', isValid: true } as InputStatus;
    setInputStatuses(newInputStatuses);
    if (basketInvoiceData) {
      console.log(basketInvoiceData);
      setIsEditMode(true);
      setNip(basketInvoiceData.nip);
      setInvoiceData(basketInvoiceData);
      updateInputStatuses();
    }
  }, [basketInvoiceData]);

  const updateInputStatuses = (): void => {};

  return (
    <>
      <Grid container>
        {!displayToolbarReturnButton && (
          <Grid item sx={{ py: 3 }} xs={12}>
            <ReturnButton onClick={() => navigate(getReturnUrl(searchParams, '/summary'))}>
              <>{t('common:buttons.back')}</>
            </ReturnButton>
          </Grid>
        )}
        <Grid item sx={{ py: 3 }} xs={12}>
          <Typography>
            <>{t('common:summary.addCompanyData')}</>
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ backgroundColor: 'white', p: 2 }}>
          <Stack direction="column" spacing={2}>
            <Stack direction="column" spacing={3}>
              <Label>NIP</Label>
              <Input value={nip} onChange={(v) => handleNipChange(v.target)} placeholder="NIP" helperText={inputStatuses.nip?.message} error={!(inputStatuses.nip?.isValid ?? true)} name="nip"></Input>
            </Stack>
            <Stack direction="column" spacing={3} alignItems="center">
              {!isEditMode && (
                <>
                  <MiddleWidthButton onClick={handleGetKRSData}>
                    <>{t('common:summary.getKRSData')}</>
                  </MiddleWidthButton>
                  <MiddleWidthButton onClick={() => setIsEditMode(true)}>
                    <>{t('common:summary.enterData')}</>
                  </MiddleWidthButton>
                </>
              )}
              {/* {isEditMode && <MiddleWidthButton>Edytuj dane</MiddleWidthButton>} */}
            </Stack>
            {isEditMode && (
              <Stack direction="column" spacing={2}>
                <Label>
                  <>{t('common:summary.nameOptional')}</>
                </Label>
                <Input placeholder={t('common:summary.name')} value={invoiceData.firstName ?? ''} onChange={(e) => handleInvoiceDataChange(e.target)} name="firstName"></Input>
                <Label>
                  <>{t('common:summary.surnameOptional')}</>
                </Label>
                <Input placeholder={t('common:summary.surname')} value={invoiceData.lastName ?? ''} onChange={(e) => handleInvoiceDataChange(e.target)} name="lastName"></Input>
                <Label>
                  <>{t('common:summary.companyName')}</>
                </Label>
                <Input placeholder={t('common:summary.companyName')} value={invoiceData.name ?? ''} onChange={(e) => handleInvoiceDataChange(e.target)} name="name" helperText={inputStatuses.name?.message} error={!inputStatuses.name?.isValid}></Input>
                <Label>
                  <>{t('common:summary.companyAddress')}</>
                </Label>
                <Input
                  placeholder={t('common:summary.streetAndNumber')}
                  value={invoiceData.address.streetAndNumber ?? ''}
                  onChange={(e) => handleInvoiceAddressChange(e.target)}
                  name="streetAndNumber"
                  helperText={inputStatuses.streetAndNumber?.message}
                  error={!inputStatuses.streetAndNumber?.isValid}
                ></Input>
                <Input placeholder={t('common:summary.postalCode')} value={invoiceData.address.postalCode ?? ''} onChange={(e) => handleInvoiceAddressChange(e.target)} name="postalCode" helperText={inputStatuses.postalCode?.message} error={!inputStatuses.postalCode?.isValid}></Input>
                <Input placeholder={t('common:summary.city')} value={invoiceData.address.city ?? ''} onChange={(e) => handleInvoiceAddressChange(e.target)} name="city" helperText={inputStatuses.city?.message} error={!inputStatuses.city?.isValid}></Input>
                <Divider></Divider>
                <GreenButton onClick={handleSave} sx={{ alignSelf: 'center' }}>
                  <>{t('common:buttons.save')}</>
                </GreenButton>
              </Stack>
            )}
          </Stack>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openError}
        onClose={() => setoOpenError(false)}
        autoHideDuration={3000}
      >
        <BergAlert onClose={() => setoOpenError(false)} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </BergAlert>
      </Snackbar>
    </>
  );
};

export default Search;
