/* eslint-disable react-hooks/exhaustive-deps */
import { Divider, Paper, Stack, styled, Typography } from '@mui/material';
import { format } from 'date-fns';
import { Suspense, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import PaymentWrapper from '../components/PaymentWrapper';
import { SummaryText } from '../components/styled-components';
import { BasketTicket } from '../modules/basket/models';
import { Ticket } from '../modules/tickets/models';
import { addOrUpdateBasketTicket } from '../modules/basket/slice';
import { fetchTickets } from '../modules/tickets/operations';
import { getParkingTickets } from '../modules/tickets/selectors';
import { useAppDispatch, useAppSelector } from '../utils/hooks';
import { getAppDetails, IsPrinterConnected } from '../utils/terminal';
import { toWithReturnParam } from '../utils/navigationHelper';
import { useProcessPayment, useSinglePaymentType } from '../modules/payment/hooks';
import PrinterWarningDialog from './summary/PrinterWarningDialog';

const PlateText = styled(Typography)({ textAlign: 'center', fontWeight: 700, fontSize: '3rem', color: '#6260A8' });
const TicketTypeText = styled(Typography)({ fontSize: '1.5rem', color: '#5E5E5E' });
const PriceText = styled(Typography)({ fontWeight: 600, fontSize: '2.2rem', color: '#5E5E5E' });

const ParkingSummary = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let location = useLocation();
  const parkingTickets = useAppSelector((s) => getParkingTickets(s.tickets));
  const [singlePaymentType] = useSinglePaymentType();
  const [processPayment] = useProcessPayment(singlePaymentType);
  const { plate } = useParams();
  const [searchParams] = useSearchParams();
  const [openPrinterWarning, setOpenPrinterWarning] = useState<boolean>(false);

  const basketCreated = useRef(false);
  useEffect(() => {
    if (searchParams.get('variantId')) {
      dispatch(fetchTickets());
    }
  }, []);

  useEffect(() => {
    const pt = getParkingTicket();
    if (pt && !basketCreated.current) {
      basketCreated.current = true;
      const basketTicket = {
        ammount: pt.price.amount,
        count: 1,
        question: {
          answer: plate,
          questionId: pt.question.questionId,
        },
        ticket: pt,
      } as BasketTicket;
      dispatch(addOrUpdateBasketTicket(basketTicket));
    }
  }, [parkingTickets]);

  const getParkingTicket = (): Ticket | undefined => {
    const variantId = searchParams.get('variantId');
    if (variantId && parkingTickets && parkingTickets.length > 0) {
      const pt = parkingTickets?.find((x) => x.variantId === Number(variantId));
      if (pt) {
        return pt;
      }
      console.warn(`Cannot find parking ticket for variant: ${variantId}`);
      return;
    } else {
      console.warn(`Cannot get parking ticket for empty variantId`);
    }
  };

  const handlePaymentClick = async () => {
    const parkingTicket = getParkingTicket();
    if (!parkingTicket) {
      //TODO: display user info
      console.error('Empty parking ticket. Cannot start payment.');
    }
    const appDetails = await getAppDetails();
    if (appDetails.hotspotRequired) {
      console.log('[ParkingSummary] Hotspot is required');
      const isHotspotEnabled = window.BtDevice.isHotspotEnabled();
      if (!isHotspotEnabled) {
        console.log('[ParkingSummary] Hotspot is NOT enabled');
        window.BtDevice.openHotspotSettings();
        return;
      }
      console.log('[ParkingSummary] Hotspot is enabled');
    }
    console.log('[ParkingSummary] Hotspot is NOT required');

    if (appDetails.fiscalPrinterRequired) {
      const isPrinterConnected = await IsPrinterConnected();
      console.log('[ParkingSummary] isPrinterConnected', isPrinterConnected);
      if (!isPrinterConnected) {
        setOpenPrinterWarning(true);
        return;
      }
    }

    if (singlePaymentType) {
      await processPayment();
    } else {
      navigate(toWithReturnParam(location, '/payment_wizard'));
    }
  };

  return (
    <>
      <PaymentWrapper title="Podsumowanie" onPaymentClick={handlePaymentClick} displayCompanyDetailsButton={true}>
        <>
          <Paper>
            <Stack direction="column" sx={{ width: '100%' }}>
              <Stack direction="row" justifyContent="space-between" sx={{ width: '100%', px: 4, my: 2 }}>
                <TicketTypeText>{getParkingTicket()?.name}</TicketTypeText>
                <PriceText sx={{ alignSelf: 'right' }}>
                  <>
                    {getParkingTicket()?.price?.amount} {getParkingTicket()?.price?.currency}
                  </>
                </PriceText>
              </Stack>
              <SummaryText sx={{ px: 4, my: 2 }}>{format(new Date(), 'dd.MM.yyyy')}</SummaryText>
              <Divider sx={{ borderTop: '2px dashed #6260A8', my: 2, width: '100%' }}></Divider>
              <PlateText sx={{ my: 2 }}>{plate}</PlateText>
            </Stack>
          </Paper>
        </>
      </PaymentWrapper>
      <Suspense>
        <PrinterWarningDialog open={openPrinterWarning} handleClose={() => setOpenPrinterWarning(() => false)}></PrinterWarningDialog>
      </Suspense>
    </>
  );
};

export default ParkingSummary;
