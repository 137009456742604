import { i18n } from '../i18n/i18n';
import { Client } from '../setup/Client';
import { Layout } from '../setup/Layout';
import axiosInstance from './axios';
import * as appConstants from './const';
import { Macs } from './const';

export const getAppInfo = async (): Promise<AppDetails> => {
  const appDetailsLs = GetFromLocaStorage();
  if (appDetailsLs !== null) {
    console.log('Getting AppDetails from sessionStorage');
    return appDetailsLs;
  }
  console.log('Window size w:' + window.screen.width + ' h: ' + window.screen.height + ' aw: ' + window.screen.availWidth + ' ah: ' + window.screen.availHeight + ' pd: ' + window.screen.pixelDepth + ' dpi: ' + window.devicePixelRatio);

  const mac = await getDeviceMac();
  try {
    if (window.BtDevice) {
      console.log('Getting AppDetails from API');
      const result = await axiosInstance.get<AppDetails>('v1/paybox/application-details?mac=' + mac);
      console.log('App details get from api: ' + JSON.stringify(result.data));
      SaveToLocalStorage(result.data);
      setLanguage(result.data.language);
      return result.data;
    } else {
      console.log('Geting TEST AppDetails');
      SaveToLocalStorage(appConstants.SamplePNGSAppDetails);
      setLanguage(appConstants.SamplePNGSAppDetails.language);
      return appConstants.SamplePNGSAppDetails;
    }
  } catch (error) {
    console.error('Failed getting device info: ' + JSON.stringify(error));
    return {} as AppDetails;
  }
};

const setLanguage = async (language: string) => {
  console.log('Setting language', language);
  await i18n.changeLanguage(language);
};

export const getEncodedImage = (qrCode): string => {
  try {
    return window.BtDevice.generateQR(qrCode);
  } catch (e) {
    console.error('Failed generating QR', JSON.stringify(e));
    return '';
  }
};

export const getDeviceMac = (): Promise<string> => {
  return new Promise((resolve, reject) => {
    try {
      if (window.BtDevice) {
        const response = window.BtDevice.getDeviceMac();
        console.log('[TERMINAL] Terminal - getDeviceMac - Success:', response);
        resolve(response);
      } else {
        resolve(Macs.PngsWithPrinter);
        console.log('[TERMINAL][Local] Terminal - getDeviceMac - Success:');
      }
    } catch (error) {
      console.error('[TERMINAL] Terminal - getDeviceMac - Error:', JSON.stringify(error));
      reject('ERROR_MAC');
    }
  });
};

export const printTestTicket = (): Promise<string> => {
  return new Promise((resolve, reject) => {
    try {
      if (window.BtDevice) {
        const response = window.BtDevice.printTestTicket();
        console.log('[TERMINAL] Terminal - printTestTicket - Success:', response);
        resolve(response);
      } else {
        console.log('[TERMINAL][Local] Terminal - printTestTicket - Success:');
        resolve('NO_PHYSICAL_DEVICE');
      }
    } catch (error) {
      console.error('[TERMINAL] Terminal - printTestTicket - Error:', JSON.stringify(error));
      reject('ERROR_PRINT_TEST_TICKET');
    }
  });
};

const GetFromLocaStorage = (): AppDetails | null => {
  const appInfoStr = sessionStorage.getItem(appConstants.AppInfoKey);
  if (appInfoStr == null) {
    console.log("AppDetails doesn't exist in sessionStorage");
    return null;
  }
  const appInfo = JSON.parse(appInfoStr) as AppDetails;
  if (appInfo.expireOn == null) {
    console.log('ExpireOn was empty');
    return null;
  }
  if (Date.now() > appInfo.expireOn) {
    console.log('AppDetails expired');
    sessionStorage.removeItem(appConstants.AppInfoKey);
    return null;
  }
  return appInfo;
};

function SaveToLocalStorage(appDetails: AppDetails) {
  const expireOn = new Date(Date.now() + 300000).getTime();
  appDetails.expireOn = expireOn;
  // console.log('Saving AppDetails to sessionStorage : ' + JSON.stringify(appDetails));
  sessionStorage.setItem(appConstants.AppInfoKey, JSON.stringify(appDetails));
}

export interface AppDetails {
  id: number;
  name: string;
  appType: string;
  language: 'en';
  languageVersions: string[];
  devices: DeviceDetails[];
  expireOn: number | null;
  client: Client;
  layout: Layout;
  enabledFeatures: AppFeature[];
  hotspotRequired: boolean;
  fiscalPrinterRequired: boolean;
  acceptedPaymentTypes: AppPaymentType[];
  embeddedQrScanner: boolean;
  embeddedLprScanner: boolean;
  primaryMode: PrimaryMode;
  paymentLogo: PaymentProvider;
}

export interface DeviceDetails {
  id: number;
  name: string;
  type: string;
  ip: string;
  port: string;
  function: string;
  additionalSettings: object;
}

export enum AppFeature {
  TicketSale = 'ticketSale',
  TicketSearchByCarPlates = 'ticketSearchByCarPlates',
  TicketScan = 'ticketScan',
}

export enum AppPaymentType {
  CreditCard = 'credit_card',
  Cash = 'cash',
}

export enum PrimaryMode {
  None = 'none',
  Sales = 'sales',
}

export enum PaymentProvider {
  PayTel = 'payTel',
  VivaWallet = 'vivaWallet',
}
