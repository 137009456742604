import styled from '@emotion/styled';
import { Box, Paper, Stack, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { ReturnButton } from '../../../components/styled-components';

const HeaderText = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  color: 'white',
  fontSize: '35px',
  textAlign: 'center',
}));

const InfoText = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: '25px',
  textAlign: 'center',
}));

const Generated = () => {
  const navigate = useNavigate();

  return (
    <>
      <Grid2 container spacing={3} sx={{ py: 3, height: '100%', backgroundImage: `url(${'/images/pole/mountain.png'})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom right', backgroundSize: '300px' }}>
        <Grid2 xs={6} sx={{ backgroundImage: `url(${'/images/pole/tourist2.png'})`, backgroundRepeat: 'no-repeat', backgroundSize: '500px', backgroundPosition: 'center' }}></Grid2>
        <Grid2 xs={6} display="flex" justifyContent="center" alignItems="center">
          <Paper sx={{ width: '100%', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', pb: 2 }}>
            <Stack direction="column" spacing={4} alignItems="center">
              <Box sx={{ backgroundColor: '#4398D1', width: '100%', height: 4 / 4, py: 5 }}>
                <HeaderText>Weź bilet i wyjedź na parking</HeaderText>
              </Box>
              <Box sx={{ py: 4 }}>
                <InfoText>Bilet należy opłacić w kasie samoobsługowej na terenie parkingu lub u obsługi parkingu.</InfoText>
              </Box>
              <ReturnButton onClick={() => navigate('/')}>Powrót</ReturnButton>
            </Stack>
          </Paper>
        </Grid2>
      </Grid2>
    </>
  );
};

export default Generated;
