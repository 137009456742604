import { Location } from 'react-router-dom';

export const toWithReturnParam = (location: Location, to: string): string => {
  let url = to;
  url += `?r=${location.pathname}`;
  if (location.search) {
    url += `&${location.search.substring(1)}`;
  }
  return url;
};

export const getReturnUrl = (searchParams: URLSearchParams, defaultUrl: string): string => {
  const rParam = searchParams.get('r');

  if (rParam) {
    let params = '';
    if (searchParams.getAll('r').length > 1) {
      params += `&r=${searchParams.getAll('r')[1]}`;
    }
    if (searchParams.getAll('r').length > 2) {
      params += `&r=${searchParams.getAll('r')[2]}`;
    }
    const variantParam = searchParams.get('variantId');
    if (variantParam) {
      params += `&variantId=${variantParam}`;
    }
    if (params.length === 0) {
      return rParam;
    }
    return rParam + '?' + params.substring(1);
  }

  return defaultUrl;
};
