import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Client } from '../../setup/Client';
import { PngsClientSetup, TpnClientSetup, MesseClientSetup } from '../../setup/ClientSetup';
import { Slice } from '../../types';
import { AppDetails } from '../../utils/device';
import { LayoutStateModel } from './models';
import { getAppSettings } from './operations';

const initialState = {
  isLoadingDone: false,
  embeddedQrScanner: false,
} as LayoutStateModel;

const layoutSlice = createSlice({
  name: Slice.Layout,
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getAppSettings.fulfilled, (state, action: PayloadAction<AppDetails>) => {
      state.client = action.payload.client;
      state.layout = action.payload.layout;
      state.enabledFeatures = action.payload.enabledFeatures;
      state.acceptedPaymentTypes = action.payload.acceptedPaymentTypes;
      state.embeddedQrScanner = action.payload.embeddedQrScanner;
      state.embeddedLprScanner = action.payload.embeddedLprScanner;
      state.primaryMode = action.payload.primaryMode;
      state.paymentProvider = action.payload.paymentLogo;
      switch (state.client) {
        case Client.PNGS:
          state.clientSetup = PngsClientSetup;
          break;
        case Client.TPN:
          state.clientSetup = TpnClientSetup;
          break;
        case Client.Messe:
          state.clientSetup = MesseClientSetup;
          break;
        default:
          console.error(`Cannot map client setup ${state.client}`);
      }
      state.isLoadingDone = true;
    });
  },
});

export const {} = layoutSlice.actions;
export default layoutSlice.reducer;
