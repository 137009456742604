import { Box, Stack, styled, Typography } from '@mui/material';
import { format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { useEffect, useState } from 'react';
import { getClientLayout } from '../modules/layout/selectors';
import { Layout } from '../setup/Layout';
import { useAppSelector } from '../utils/hooks';

const BatteryLevel = () => {
  const [currentTime, setCurrentTime] = useState<string>();
  const [batteryLevel, setBatteryLevel] = useState<string>();

  const layout = useAppSelector((x) => getClientLayout(x.layout));

  useEffect(() => {
    setTime();
    setInterval(() => {
      setTime();
    }, 1000);
  }, []);

  useEffect(() => {
    if (layout === Layout.Mobile) {
      getBatteryLevel();

      setInterval(() => {
        getBatteryLevel();
      }, 30 * 1000);
    }
  }, [layout]);

  const setTime = () => {
    const utcDate = zonedTimeToUtc(new Date(), 'Europe/Warsaw');
    setCurrentTime(format(utcDate, 'HH:mm'));
  };

  const getBatteryLevel = () => {
    if (window.BtDevice) {
      try {
        const batteryLevel = window.BtDevice.getBatteryLevel();
        setBatteryLevel(String(batteryLevel) + '%');
      } catch (e) {
        console.error(JSON.stringify(e));
        setBatteryLevel('?');
      }
    } else {
      setBatteryLevel('?');
    }
  };

  const TimeText = styled(Typography)({
    color: '#58585A',
  });

  return (
    <>
      <Stack direction="row" spacing={2}>
        <TimeText>{currentTime}</TimeText>
        {layout === Layout.Mobile && (
          <Stack direction="row" spacing={1} alignItems="center">
            <TimeText>{batteryLevel}</TimeText>
            <Box component="img" src="/images/battery.png" width="34px" height="19px"></Box>
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default BatteryLevel;
