import { ClientSetup } from '../../setup/ClientSetup';
import { Layout } from '../../setup/Layout';
import {AppFeature, AppPaymentType, PaymentProvider, PrimaryMode} from '../../utils/device';
import { LayoutStateModel } from './models';

export const getClientSetup = (state: LayoutStateModel): ClientSetup => {
  return state.clientSetup;
};

export const getClientLayout = (state: LayoutStateModel): Layout => {
  return state.layout;
};

export const getIsLoadingDone = (state: LayoutStateModel): boolean => {
  return state.isLoadingDone;
};

export const getEnabledFeatures = (state: LayoutStateModel): AppFeature[] => {
  return state.enabledFeatures;
};

export const getAcceptedPaymentTypes = (state: LayoutStateModel): AppPaymentType[] => {
  return state.acceptedPaymentTypes;
};

export const getEmbeddedQrScanner = (state: LayoutStateModel): boolean => {
  return state.embeddedQrScanner;
};

export const getPrimaryMode = (state: LayoutStateModel): PrimaryMode => {
  return state.primaryMode;
};

export const getEmbeddedLprScanner = (state: LayoutStateModel): boolean => {
  return state.embeddedLprScanner;
};

export const getPaymentProvider = (state: LayoutStateModel): PaymentProvider => {
  return state.paymentProvider;
};
