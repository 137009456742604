import { Container, createTheme, styled, ThemeOptions } from '@mui/material';

const themeOptions: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
    allVariants: {
      fontWeight: 500,
    },
    fontSize: 14, // default value
    htmlFontSize: 16, // default value
  },
  palette: {
    secondary: {
      main: '#FF4242',
    },
    primary: {
      main: '#9464FC',
    },
  },
};

export const theme = createTheme(themeOptions);

export const MaxHeightContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    height: 'calc(100% - 63px)',
  },
  [theme.breakpoints.up('md')]: {
    height: 'calc(100% - 80px)',
  },
  [theme.breakpoints.up('lg')]: {
    height: 'calc(100% - 100px)',
  },
  [theme.breakpoints.up('xl')]: {
    height: 'calc(100% - 128px)',
  },
  backgroundImage: `url(${'/images/pole/pole_bg.png'})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
}));
