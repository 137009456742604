import { Box, Stack, styled, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import CenteredBox from './CenteredBox';
import { BubbleText, InfoText } from './tickets/PoleValidTicket';

const SupportContact = () => {
  const navigate = useNavigate();
  return (
    <CenteredBox onClick={() => navigate('/')}>
      <Stack direction="column" spacing={3} alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
        <Stack spacing={0} alignItems="center" justifyContent="center">
          <motion.div initial={{ scale: 0, rotate: 180 }} animate={{ scale: 1, rotate: 0 }} transition={{ delay: 1 }} style={{ width: '100%', height: '100%' }}>
            <Stack direction="column" sx={{ width: '100%', minHeight: '140px', backgroundImage: "url('/images/pole/comic_baloon_long.png')", backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <BubbleText>Kontakt z obsługą</BubbleText>
            </Stack>
          </motion.div>
          <Box component="img" src="/images/pole/help_phone.png" sx={{ width: 'calc(100% - 15vw)' }}></Box>
        </Stack>
        <InfoText sx={{ fontWeight: 500 }}>Zadzwoń na numer telefonu:</InfoText>
        <InfoText sx={{ fontWeight: 700 }}>18 202 32 00</InfoText>
      </Stack>
    </CenteredBox>
  );
};

export default SupportContact;
