import { CssBaseline, GlobalStyles, ThemeProvider } from '@mui/material';
import { useEffect, useLayoutEffect } from 'react';
import { getAppSettings } from '../../modules/layout/operations';
import { getIsLoadingDone } from '../../modules/layout/selectors';
import { fetchStats } from '../../modules/tickets/operations';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import PreLoader from '../PreLoader';
import TopAppBar, { ResponsiveToolbar } from '../TopAppBar';
import { MaxHeightContainer, theme } from './styles';

interface LayoutProps {
  children: JSX.Element;
}

const Layout = ({ children }: LayoutProps) => {
  const dispatch = useAppDispatch();
  const isDone = useAppSelector((l) => getIsLoadingDone(l.layout));

  useLayoutEffect(() => {
    console.log('Layout - useLayoutEffect');
    dispatch(getAppSettings());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('Layout - useEffect');
    dispatch(fetchStats());
    const interval = setInterval(() => {
      console.log('Layout - scheduled - fetch stats');
      dispatch(fetchStats());
    }, 30000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pageContent = (
    <>
      <TopAppBar />
      <ResponsiveToolbar />
      <MaxHeightContainer maxWidth={false}>{children}</MaxHeightContainer>
    </>
  );
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles
          styles={{
            body: { backgroundColor: '#F2F2F2', height: '100%' },
            html: { backgroundColor: '#F2F2F2', height: '100%' },
          }}
        />
        {isDone ? pageContent : <PreLoader></PreLoader>}
      </ThemeProvider>
    </>
  );
};

export default Layout;
