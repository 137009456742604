import styled from '@emotion/styled';
import { Alert, Box, CircularProgress, Snackbar, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReturnButton, VioletButton } from '../components/styled-components';
import useDisplayToolbarReturnButton from '../layout/layoutHooks';
import { getEmbeddedQrScanner } from '../modules/layout/selectors';
import { getTicketDetails } from '../modules/tickets/operations';
import { useAppSelector } from '../utils/hooks';

const ScanHeader = styled(Typography)({
  fontSize: '1.125rem',
  color: '#2B2A2A',
  lineHeight: '27px',
  fontWeight: 700,
});

const BarcodeScanner = () => {
  const { t } = useTranslation(['common']);
  const navigate = useNavigate();
  const displayToolbarReturnButton = useDisplayToolbarReturnButton();
  const [openError, setOpenError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [openLoader, setOpenLoader] = useState<boolean>(false);
  const embeddedQrScanner = useAppSelector((x) => getEmbeddedQrScanner(x.layout));

  useEffect(() => {
    const OpenTicketStatus = async (ticketId: string) => {
      setOpenLoader(true);
      const ticket = await getTicketDetails(ticketId);
      console.log('Scanned ticket details: ' + JSON.stringify(ticket));
      setOpenLoader(false);
      if (ticket) {
        if (ticket.ticketWarnings.length > 0) {
          navigate(`/ticket/invalid/${ticketId}`);
          return;
        } else {
          navigate(`/ticket/valid/${ticketId}`);
          return;
        }
      } else {
        setErrorMessage(t('common:scanner.wrongTicketNumberMessage').toString());
        setOpenError(true);
      }
    };
    if (window.BtApp) {
      if (!embeddedQrScanner) {
        console.log('Starting CameraQrScanner ');
        window.BtDevice.startCameraQrScanner();
      }
      console.log('assign handleQrReader');
      window.BtApp.handleQrReader = (data: string) => {
        if (data === undefined || data === null) {
          console.log('Wrong ticket number: ' + JSON.stringify(data));
          setErrorMessage(t('common:scanner.wrongTicketNumberMessage').toString());
          setOpenError(true);
          return;
        }
        let ticketId = '';
        if (data.startsWith('http') && data.indexOf('uuid')) {
          ticketId = data.substring(data.indexOf('uuid') + 5);
        } else {
          ticketId = data;
        }
        console.log('Scanned: ' + ticketId);
        if (ticketId) {
          OpenTicketStatus(ticketId);
          return;
        }
      };
    }
    return () => {
      if (window.BtApp && window.BtApp.handleQrReader) {
        console.log('Unregister handleQrReader func');
        window.BtApp.handleQrReader = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScanClicked = () => {
    console.log('Starting CameraQrScanner ');
    window.BtDevice.startCameraQrScanner();
  };

  return (
    <>
      <Stack direction="column" spacing={2} sx={{ py: 6 }}>
        {!displayToolbarReturnButton && (
          <ReturnButton onClick={() => navigate('/')}>
            <>{t('common:buttons.back')}</>
          </ReturnButton>
        )}
        {embeddedQrScanner ? (
          <ScanHeader align="center" sx={{ pt: 3 }}>
            <>{t('common:scanner.useScanner')}</>
          </ScanHeader>
        ) : (
          <Stack direction="column" spacing={3} alignItems="center">
            <ScanHeader>
              <>{t('common:scanner.scanQr')}</>
            </ScanHeader>
            <Stack sx={{ backgroundColor: '#999999', width: '100%', height: 400, justifyContent: 'center' }}>
              <Box component="img" src="/images/camera.png" alignSelf="center" justifySelf="center"></Box>
            </Stack>
            <VioletButton onClick={handleScanClicked}>
              <>{t('common:scanner.scan')}</>
            </VioletButton>
          </Stack>
        )}
        {openLoader && (
          <Stack alignItems="center" direction="column" spacing={1}>
            <CircularProgress />
            <Typography align="center">
              <>{t('common:scanner.fetchingData')}</>
            </Typography>
          </Stack>
        )}
      </Stack>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openError}
        onClose={() => setOpenError(false)}
        autoHideDuration={3000}
      >
        <Alert onClose={() => setOpenError(false)} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default BarcodeScanner;
