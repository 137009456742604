const START_PAYMENT = 'START_PAYMENT';
const CONFIRM_PAYMENT = 'CONFIRM_PAYMENT';
const START_AND_CONFIRM = 'START_AND_CONFIRM';
const GET_ORDER = 'GET_ORDER';

const actionTypes = {
  START_PAYMENT,
  CONFIRM_PAYMENT,
  START_AND_CONFIRM,
  GET_ORDER,
};

export default actionTypes;
