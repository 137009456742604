import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDetails, getAppInfo } from '../../utils/device';
import actionTypes from './actionTypes';

export const getAppSettings = createAsyncThunk(actionTypes.GET_SETTINGS, async (): Promise<AppDetails> => {
  try {
    console.log('start getting AppSettings');
    const appInfo = await getAppInfo();

    return appInfo;
  } catch (e) {
    console.error(JSON.stringify(e));
    throw e;
  }
});
