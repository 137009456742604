import { InvoiceData } from '../tickets/models';

export enum PaymentType {
  CASH = 'CASH',
  CREDIT_CARD = 'CREDIT_CARD',
  FREE_TICKET = 'FREE_TICKET',
}
export interface RequestTicket {
  variantId: number;
  quantity: number;
  question: RequestTicketQuestion;
}

export interface RequestTicketQuestion {
  answer: string;
  questionId: number;
}

export interface CreatePaymentRequest {
  tickets: RequestTicket[];
  invoiceData: InvoiceData;
}
export interface ResponseTicket {
  uuid: string;
  qrCode: string;
  name: string;
  variantName: string;
  price: number;
  vat: number;
}
export interface OrderStatus {
  orderId: string;
  totalAmount: number;
  tickets: ResponseTicket[];
}
export interface ConfirmPaymentRequest {
  amount: number;
  orderId: string;
  paymentType: PaymentType;
  transactionId: string | null;
}

export interface PaymentStateModel {
  paymentStatus: OrderStatus | null;
}
