import { Box, CircularProgress, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RedButton, VioletButton } from '../../components/styled-components';
import { getInvoiceData } from '../../modules/basket/selectors';
import { PaymentState, terminalCodeDescriptions, TerminalState, TerminalStateDescritpions } from '../../modules/payment/const';
import { CreateConfirmPaymentRequestModel } from '../../modules/payment/helpers';
import { PaymentType, ResponseTicket } from '../../modules/payment/models';
import { confirmPayment } from '../../modules/payment/operations';
import { getPaymentState } from '../../modules/payment/selectors';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { printInvoice, startPayment } from '../../utils/terminal';
import WizardSteps from './WizardSteps';

const Terminal = () => {
  const { t } = useTranslation(['common']);
  const [, setPaymentState] = useState<PaymentState>();
  const [, setCustomMessage] = useState<string>('');
  const [terminalState, setTerminalState] = useState<TerminalState>();
  const [errorCount, setErrorCount] = useState<number>(0);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [terminalStatusResponseDescription, setTerminalStatusResponseDescription] = useState<string>('');
  const paymentStatus = useAppSelector((state) => getPaymentState(state.payment));
  const invoiceData = useAppSelector((state) => getInvoiceData(state.basket));
  const theme = useTheme();
  const isDownSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isDownMDScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (window.BtApp) {
      window.BtApp.updatePaymentStatus = (terminalCode: number, defaultMessage: string) => {
        const terminalCodeInt = Number(terminalCode);
        console.log(`[TERMINAL] updatePaymentStatus:`, JSON.stringify({ terminalCode, terminalCodeInt, defaultMessage }));

        setPaymentState(PaymentState.PENDING);
        setLoading(true);
        setCustomMessage(defaultMessage || '');
        // setTerminalCode(Number(response));
        setTerminalStatusResponseDescription(terminalCodeDescriptions.get(terminalCodeInt) ?? defaultMessage);
      };

      window.BtApp.paymentFinished = (terminalCode: number, defaultMessage: string, transactionId: string) => {
        const terminalCodeInt = Number(terminalCode);
        console.log(`[TERMINAL] paymentFinished:`, JSON.stringify({ terminalCode, terminalCodeInt, defaultMessage, transactionId }));

        setCustomMessage(defaultMessage || '');
        setTerminalStatusResponseDescription(terminalCodeDescriptions.get(terminalCodeInt) ?? defaultMessage);

        switch (terminalCodeInt) {
          case 0:
            setPaymentState(PaymentState.SUCCESS);
            console.log('[TERMINAL] paymentFinished: Payment status ' + JSON.stringify(paymentStatus));
            if (paymentStatus) {
              dispatch(confirmPayment(CreateConfirmPaymentRequestModel(paymentStatus, PaymentType.CREDIT_CARD, paymentStatus?.totalAmount, transactionId)));
              startPrinting(paymentStatus.orderId, paymentStatus.tickets);
              navigate('/payment_wizard/terminal/confirmation');
            }

            break;
          default:
            setPaymentState(PaymentState.ERROR);
            setTerminalState(TerminalState.repeat);
            setErrorCount(errorCount + 1);
            break;
        }
        setLoading(false);
      };
    }
    startProcessingPayment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startPrinting = async (orderId: string, tickets: ResponseTicket[]): Promise<void> => {
    console.log('startPrinting');
    const printState = await printInvoice(orderId ?? '', tickets ?? [], invoiceData?.nip);
    console.log('Print status ' + printState);
  };

  const startProcessingPayment = async (): Promise<void> => {
    setLoading(true);
    setTerminalStatusResponseDescription('');
    setTerminalState(undefined);
    try {
      const terminalState = await startPayment(paymentStatus?.totalAmount ?? 0, paymentStatus?.orderId ?? '');

      setTerminalState(terminalState);

      if (terminalState !== TerminalState.PAYMENT_STARTED && terminalState !== TerminalState.PAYMENT_STARTED_OK) {
        setPaymentState(PaymentState.ERROR);
        setErrorCount(errorCount + 1);
        setLoading(false);
      } else {
        setPaymentState(PaymentState.PENDING);
        setErrorCount(0);
      }
    } catch (e) {
      setTimeout(() => {
        setLoading(false);
        setPaymentState(PaymentState.ERROR);
        setTerminalState(TerminalState.exception);
        setErrorCount(errorCount + 1);
        console.error('Failed startPayment', JSON.stringify(e));
      }, 2000);
    }
  };

  const handleChangePaymentType = async () => {
    navigate('/payment_wizard?r=/summary');
  };

  const handleRepeatPayment = async () => {
    console.log('Repeat payment button clicked');
    await startProcessingPayment();
  };

  return (
    <>
      <WizardSteps activeStep={1}>
        <Grid container sx={{ justifyContent: 'center', display: 'flex' }}>
          <Grid item xs={12} sx={{ justifyContent: 'center', display: 'flex', mt: 3 }}>
            <Typography align="center" sx={{ fontSize: '24px', fontWeight: '700', lineHeight: '36px' }}>
              {terminalState && TerminalStateDescritpions.get(terminalState)}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ justifyContent: 'center', display: 'flex', mt: 3 }}>
            {loading && <CircularProgress color="secondary" />}
            <Typography align="center" sx={{ fontSize: '26px', lineHeight: '45px' }}>
              {terminalStatusResponseDescription}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            {errorCount > 0 ? <Box component="img" src="/images/terminal_error.png" sx={{ height: isDownMDScreen ? '130px' : 'auto' }}></Box> : <Box component="img" src="/images/terminal_payment.png" sx={{ height: isDownMDScreen ? '130px' : 'auto' }}></Box>}
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            {terminalState !== TerminalState.PAYMENT_STARTED && terminalState !== TerminalState.PAYMENT_STARTED_OK && !loading && errorCount > 0 && (
              <Stack direction={isDownSmallScreen ? 'column' : 'row'} spacing={2}>
                <RedButton disabled={loading} onClick={handleRepeatPayment}>
                  <Typography>{t('common:payments.retryPaymentButton').toString()}</Typography>
                </RedButton>
                <VioletButton disabled={loading} onClick={handleChangePaymentType}>
                  <Typography>{t('common:payments.changePaymentTypeButton').toString()}</Typography>
                </VioletButton>
              </Stack>
            )}
          </Grid>
        </Grid>
      </WizardSteps>
    </>
  );
};

export default Terminal;
