import { initReactI18next } from 'react-i18next';
import i18next, { i18n as i18nInstance } from 'i18next';
import { languages, namespaces } from './i18n.constants';
import HttpApi from 'i18next-http-backend';

const createI18n = (language: string): i18nInstance => {
  const i18n = i18next.createInstance().use(initReactI18next);

  i18n.use(HttpApi).init({
    lng: language,
    fallbackLng: language,
    ns: [namespaces.common, namespaces.pages.hello],
    defaultNS: namespaces.common,
    debug: false,
    interpolation: { escapeValue: false },
    react: {
      useSuspense: false, //
    },
  });

  return i18n;
};

export const i18n = createI18n(languages.pl);
