import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { getPrimaryMode } from '../modules/layout/selectors';
import { AppDispatch, RootState } from '../store/store';
import { PrimaryMode } from './device';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAppReturnLink = (): string => {
  const primaryMode = useAppSelector((x) => getPrimaryMode(x.layout));

  return primaryMode === PrimaryMode.Sales ? '/tickets' : '/';
};
