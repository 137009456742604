import { Box, Stack, styled, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import CenteredBox from '../CenteredBox';

export const BubbleText = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '20px',
}));

export const InfoText = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '25px',
  textAlign: 'center',
}));

const PoleValidTicket = () => {
  const navigate = useNavigate();
  return (
    <CenteredBox onClick={() => navigate('/')}>
      <Stack direction="column" spacing={3} alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
        <Stack spacing={0} alignItems="center" justifyContent="center">
          <motion.div initial={{ scale: 0, rotate: 180 }} animate={{ scale: 1, rotate: 0 }} transition={{ delay: 1 }} style={{ width: '100%', height: '100%' }}>
            <Stack direction="column" sx={{ width: '100%', minHeight: '140px', backgroundImage: "url('/images/pole/comic_baloon_long.png')", backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <Box component="img" src="/images/valid.png" sx={{ width: 'calc(12%)', objectFit: 'contain', mt: 1 }}></Box>
              <BubbleText>Ten bilet jest ważny</BubbleText>
            </Stack>
          </motion.div>
          <Box component="img" src="/images/pole/tourist2.png" sx={{ width: 'calc(100% - 15vw)' }}></Box>
        </Stack>
        <InfoText>Możesz wjechać na parking.</InfoText>
      </Stack>
    </CenteredBox>
  );
};

export default PoleValidTicket;
