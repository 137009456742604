import axios from 'axios';
import { initializeMockAdapter } from './axios-mock';

const axiosInstance = axios.create({
  responseType: 'json',
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 1000 * 10,
});

axiosInstance.defaults.headers.post['Content-Type'] = 'application/json';
axiosInstance.defaults.headers.put['Content-Type'] = 'application/json';
if (window.BtDevice) {
  axiosInstance.defaults.headers.common['Screen-Authorization'] = 'Bearer ' + window.BtDevice.getAuthorizationToken();
  setInterval(() => {
    console.log('Refreshing token');
    axiosInstance.defaults.headers.common['Screen-Authorization'] = 'Bearer ' + window.BtDevice.getAuthorizationToken();
  }, 300000);
} else {
  axiosInstance.defaults.headers.common['Screen-Authorization'] = 'Bearer ' + process.env.REACT_APP_API_TOKEN;
}

if (process.env.NODE_ENV === 'development') {
  console.log('Initializing axios mock adapter');
  initializeMockAdapter(axiosInstance);
}

export default axiosInstance;
