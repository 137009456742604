import { TerminalState } from '../modules/payment/const';
import { ResponseTicket } from '../modules/payment/models';
import { AppDetails, DeviceDetails, getAppInfo } from './device';

export const getTerminal = async (): Promise<DeviceDetails> => {
  try {
    var appInfo = await getAppInfo();
    var deviceDetails = appInfo?.devices.find((x) => x.function === 'PAYMENT_TERMINAL');

    if (!deviceDetails) {
      throw new Error('Cannot find device with type PAYMENT_TERMINAL');
    }
    return deviceDetails;
  } catch (error) {
    console.error('Failed getting terminal: ' + JSON.stringify(error));
    return {} as DeviceDetails;
  }
};

export const getPrinter = async (): Promise<DeviceDetails | null> => {
  try {
    var appInfo = await getAppInfo();
    var deviceDetails = appInfo?.devices.find((x) => x.function === 'FISCAL_PRINTER');

    if (!deviceDetails) {
      console.log('Cannot get printer');
      return null;
    }
    console.log(JSON.stringify(deviceDetails));
    return deviceDetails;
  } catch (error) {
    console.error('Failed getting printer: ' + JSON.stringify(error));
    return {} as DeviceDetails;
  }
};

export const getParkingBarrier = async (): Promise<DeviceDetails | null> => {
  try {
    var appInfo = await getAppInfo();
    var deviceDetails = appInfo?.devices.find((x) => x.function === 'PARKING_BARRIER');

    if (!deviceDetails) {
      console.log('Cannot get printer');
      return null;
    }
    console.log(JSON.stringify(deviceDetails));
    return deviceDetails;
  } catch (error) {
    console.error('Failed getting parking barrier: ' + JSON.stringify(error));
    return {} as DeviceDetails;
  }
};

export const getTicketPrinter = async (): Promise<DeviceDetails | null> => {
  try {
    var appInfo = await getAppInfo();
    var deviceDetails = appInfo?.devices.find((x) => x.function === 'TICKET_PRINTER');

    if (!deviceDetails) {
      console.log('Cannot get ticket printer');
      return null;
    }
    console.log(JSON.stringify(deviceDetails));
    return deviceDetails;
  } catch (error) {
    console.error('Failed getting ticket printer: ' + JSON.stringify(error));
    return {} as DeviceDetails;
  }
};

export const getAppDetails = async (): Promise<AppDetails> => {
  try {
    var deviceInfo = await getAppInfo();
    return deviceInfo;
  } catch (error) {
    console.error('Failed getting App Details: ' + JSON.stringify(error));
    return {} as AppDetails;
  }
};

export const startPayment = (amount: number, orderid: string) => {
  return new Promise<TerminalState>(async (resolve, reject) => {
    try {
      const terminal = await getTerminal();
      console.log('[TERMINAL] Found Terminal: ' + JSON.stringify(terminal));
      const response = window.BtDevice.startPayment(terminal.type, terminal.ip, terminal.port, amount, orderid, JSON.stringify(terminal.additionalSettings));

      console.log('[TERMINAL] Terminal - startPayment - Success', response);

      resolve(response);
    } catch (error) {
      console.error('[TERMINAL] Terminal - startPayment - Error', JSON.stringify(error));
      reject('ERROR_PAYMENT');
    }
  });
};

export const pairTerminal = (): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    try {
      const terminal = await getTerminal();
      const response = window.BtDevice.pairTerminal(terminal.ip, terminal.port);

      resolve(response);
    } catch (error) {
      console.error('[TERMINAL] Terminal - pairTerminal - Error: Device Error', JSON.stringify(error));
      reject('ERROR_PAIRING');
    }
  });
};

export const printInvoice = async (orderId: string, tickets: ResponseTicket[], nip: string | undefined): Promise<string> => {
  try {
    const nipValue = nip === undefined ? null : nip;
    console.log('Start printing ' + orderId + ', nip: ' + JSON.stringify(nipValue) + ' ' + JSON.stringify(tickets));
    const printer = await getPrinter();
    if (printer) {
      const response = window.BtDevice.printInvoice(printer.type, printer.ip, printer.port, orderId, JSON.stringify(tickets), nipValue) as string;
      return response;
    } else {
      console.log('Cannot get printer');
      return '';
    }
  } catch (error) {
    console.error('Failed printing invoice ', JSON.stringify(error));
    return '';
  }
};

export const getEntryCarPlates = async (): Promise<string> => {
  try {
    var parkingBarrier = await getParkingBarrier();
    if (parkingBarrier) {
      var entryCarPlates = window.BtDevice.getEntryCarPlates(parkingBarrier.type, parkingBarrier.ip, parkingBarrier.port);

      return entryCarPlates;
    }
    console.log('Cannot get entryCarPlates');
    return '';
  } catch (error) {
    console.error('Failed getting Entry Car Plates invoice ', JSON.stringify(error));
    return '';
  }
};

export const IsPrinterConnected = async (): Promise<boolean> => {
  if (window.BtDevice) {
    const printer = await getPrinter();
    if (printer) {
      const result = window.BtDevice.isFiscalPrinterConnected(printer.type, printer.ip, printer.port) as boolean;
      console.log('[isFiscalPrinterConnected] Result: ' + result);
      return result;
    }
    console.error('[IsPrinterConnected] Cannot get printer data');
    return false;
  } else {
    console.log('[IsPrinterConnected] Skipping validation as window.BtDevice is not found');
    return true;
  }
};

export const printTicket = async (printerType: string, plates: string, qrCode: string): Promise<string> => {
  try {
    console.log('Start printing ticket ' + plates + ', nip: ' + qrCode);
    const printer = await getTicketPrinter();
    if (printer) {
      const response = window.BtDevice.printTicket(printer.type, plates, qrCode) as string;
      return response;
    } else {
      console.log('Cannot get ticket printer');
      return '';
    }
  } catch (error) {
    console.error('Failed printing ticket ', JSON.stringify(error));
    return '';
  }
};
