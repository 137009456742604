import { AnimatePresence, motion } from 'framer-motion';
import { Outlet, Route, Routes, useLocation } from 'react-router-dom';
import Layout from './layout/layout/Layout';
import Tickets from './pages/Tickets';
import PaymentWizard from './pages/PaymentWizard';
import Cash from './pages/paymentWizzard/Cash';
import Terminal from './pages/paymentWizzard/Terminal';
import Confirmation from './pages/paymentWizzard/Terminal/Confirmation';
import QRCode from './pages/QRCode';
import Summary from './pages/Summary';
import Home from './pages/Home';
import ParkingPlate from './pages/ParkingPlate';
import ParkingSummary from './pages/ParkingSummary';
import BarcodeScanner from './pages/BarcodeScanner';
import ValidTicket from './pages/ticket-status/ValidTicket';
import InValidTicket from './pages/ticket-status/InvalidTicket';
import CompanyDetails from './pages/summary/CompanyDetails';
import PlateSearcher from './pages/PlateSearcher';
import SearchResult from './pages/plate-searcher/SearchResult';
import FreeTickets from './pages/tickets/FreeTickets';
import './i18n/i18n';

export const Wrapper = () => {
  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={{
        initial: {
          opacity: 0,
          height: '100%',
        },
        in: {
          opacity: 1,
        },
        out: {
          opacity: 0,
        },
      }}
      transition={{
        type: 'spring',
        damping: 10,
        stiffness: 50,
      }}
    >
      <Outlet />
    </motion.div>
  );
};

const App = () => {
  const location = useLocation();
  return (
    <Layout>
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.key}>
          <Route element={<Wrapper></Wrapper>}>
            <Route path="/" element={<Home></Home>}></Route>
            <Route path="tickets" element={<Tickets></Tickets>}></Route>
            <Route path="tickets/free" element={<FreeTickets></FreeTickets>}></Route>
            <Route path="vehicle" element={<ParkingPlate></ParkingPlate>}></Route>
            <Route path="plate_search" element={<PlateSearcher></PlateSearcher>}></Route>
            <Route path="plate_search/result/:plate" element={<SearchResult></SearchResult>}></Route>
            <Route path="vehicle/ticket/:plate" element={<ParkingSummary></ParkingSummary>}></Route>
            <Route path="summary" element={<Summary />}></Route>
            <Route path="summary/company-details" element={<CompanyDetails />}></Route>
            <Route path="payment_wizard" element={<PaymentWizard />}></Route>
            <Route path="payment_wizard/terminal" element={<Terminal />}></Route>
            <Route path="payment_wizard/cash" element={<Cash />}></Route>
            <Route path="payment_wizard/terminal/confirmation" element={<Confirmation />}></Route>
            <Route path="qrcodes" element={<QRCode />}></Route>
            <Route path="scan" element={<BarcodeScanner />}></Route>
            <Route path="/ticket/valid/:id" element={<ValidTicket />}></Route>
            <Route path="/ticket/invalid/:id" element={<InValidTicket />}></Route>
          </Route>
        </Routes>
      </AnimatePresence>
    </Layout>
  );
};

export default App;
