import { createAsyncThunk } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import axiosInstance from '../../utils/axios';
import { getAppDetails } from '../../utils/terminal';
import { InvoiceData, StatModel, Ticket, TicketDetails } from './models';
import actionTypes from './types';

export const fetchTickets = createAsyncThunk(actionTypes.FETCH_TICKETS, async () => {
  try {
    const appDetails = await getAppDetails();
    const response = await axiosInstance.get<Ticket[]>(`/v1/paybox/${appDetails.id}/tickets/available`);
    console.log('Received available tickets: ' + JSON.stringify(response.data));
    return response.data;
  } catch (error) {
    console.error(JSON.stringify(error));
    return [] as Ticket[];
  }
});

export const fetchStats = createAsyncThunk(actionTypes.FETCH_STATS, async () => {
  try {
    const appDetails = await getAppDetails();
    const response = await axiosInstance.get<StatModel[]>(`/v1/paybox/${appDetails.id}/tickets/availability-pools`);
    return response.data;
  } catch (error) {
    console.error(JSON.stringify(error));
    return [] as StatModel[];
  }
});

export const getTicketDetails = async (ticketId: string): Promise<TicketDetails | null> => {
  try {
    const appDetails = await getAppDetails();
    const response = await axiosInstance.get<TicketDetails>(`/v1/paybox/${appDetails.id}/tickets/${ticketId}/details`);
    return response.data;
  } catch (error) {
    console.error(JSON.stringify(error));
    return null;
  }
};

export const getTicketsByPlates = async (plates: string): Promise<TicketDetails[] | null> => {
  try {
    const appDetails = await getAppDetails();
    const response = await axiosInstance.get<TicketDetails[]>(`/v1/paybox/${appDetails.id}/tickets/search?plates=${plates}`);
    return response.data;
  } catch (error) {
    console.error(JSON.stringify(error));
    return null;
  }
};

export const getInvoiceDataFromKrs = async (nip: string): Promise<InvoiceData | null> => {
  try {
    const appDetails = await getAppDetails();
    const response = await axiosInstance.get<InvoiceData>(`/v1/paybox/${appDetails.id}/tickets/invoice-data?nip=${nip}`);
    return response.data;
  } catch (error) {
    console.error(JSON.stringify(error));
    return null;
  }
};

export const saveAnswer = async (ticketId: string, answer: string, orderId: string, questionAnswerId: number): Promise<void> => {
  try {
    const appDetails = await getAppDetails();
    const data = JSON.stringify({
      orderId: orderId,
      question: {
        questionAnswerId: questionAnswerId,
        answer: answer,
      },
    });
    await axiosInstance.put(`/v1/paybox/${appDetails.id}/tickets/${ticketId}/question`, data);
  } catch (error) {
    console.error(JSON.stringify(error));
  }
};

export const extendTicket = async (ticketId: string): Promise<boolean> => {
  try {
    const appDetails = await getAppDetails();
    const data = JSON.stringify({
      newDate: format(new Date(), 'yyyy-MM-dd'),
    });
    await axiosInstance.put(`/v1/paybox/${appDetails.id}/tickets/${ticketId}/extend`, data);
    return true;
  } catch (error) {
    console.error(JSON.stringify(error));
    return false;
  }
};
