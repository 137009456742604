import { combineReducers } from '@reduxjs/toolkit';
import ticktetSlice from '../modules/tickets/slice';
import basketSlice from '../modules/basket/slice';
import paymentSlice from '../modules/payment/slice';
import layoutSlice from '../modules/layout/slice';

const rootReducer = combineReducers({
  tickets: ticktetSlice,
  basket: basketSlice,
  payment: paymentSlice,
  layout: layoutSlice,
});

export default rootReducer;
