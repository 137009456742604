import { useMediaQuery, useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';

const availablePagesForReturnButtonToolbar = new Set<string>(['/tickets', '/tickets/free', '/summary', '/summary/company-details', '/payment_wizard', '/scan']);

const useDisplayToolbarReturnButton = () => {
  const location = useLocation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const displayToolbarReturnButtonSm = (): boolean => {
    if (isSmallScreen && availablePagesForReturnButtonToolbar.has(location.pathname)) {
      return true;
    }
    return false;
  };

  return displayToolbarReturnButtonSm();
};

export default useDisplayToolbarReturnButton;
