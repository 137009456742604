export type Ticket = {
  variantId: number;
  name: string;
  available: number;
  price: Price;
  type: TicketType;
  ticketSubType?: TicketSubType;
  question: Question;
};

export type Price = {
  amount: number;
  //TODO: enum or const
  currency: string;
};

export interface TicketStateModel {
  tickets: Ticket[];
  stats: StatModel[];
}

export enum TicketType {
  NORMAL = 'NORMAL',
  REDUCED = 'REDUCED',
  FREE = 'FREE',
  PARKING = 'PARKING',
}

export enum TicketSubType {
  None = 'none',
  PassengerCar = 'passengerCar',
  Camper = 'camper',
  Motorcycle = 'motorcycle',
  Bus = 'bus',
  DisabledDriver = 'disabledDriver',
}

export enum TicketOption {
  TicketPayment = 'ticketPayment',
  TicketReschedule = 'ticketReschedule',
}

export enum TicketWarning {
  TicketNotPaid = 'ticketNotPaid',
  TicketOverdue = 'ticketOverdue',
  TicketFutureDate = 'ticketFutureDate',
}

export interface StatModel {
  date: string;
  dateFrm: Date;
  availableTickets: number;
  originalAvailability: number;
}

export interface Question {
  questionId: number;
  question: string;
}

export interface TicketDetails {
  uuid: string;
  ticketId: string;
  orderId: string;
  qrCode: string;
  variantId: number;
  variantName: string;
  name: string;
  available: number;
  price: number;
  currency: string;
  vat: number;
  paymentState: string;
  paymentDate?: string | null;
  useCount: number;
  useLimit: number;
  validSince: string;
  validUntil: string;
  question: QuestionAnswer;
  ticketOptions: TicketOption[];
  ticketWarnings: TicketWarning[];
  order: Order;
}

export type Order = {
  id: string;
  totalAmount: number;
};

export interface QuestionAnswer {
  questionAnswerId: number;
  question: string;
  answer: string;
}

export interface InvoiceData {
  firstName: string;
  lastName: string;
  name: string;
  nip: string;
  address: Address;
}

export interface Address {
  city: string;
  postalCode: string;
  streetAndNumber: string;
}
