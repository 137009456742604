import { Typography, useMediaQuery, useTheme, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../utils/hooks';
import { useEffect } from 'react';
import { fetchStats, fetchTickets } from '../modules/tickets/operations';
import MainTickets from './tickets/MainTickets';
import { ReturnButton } from '../components/styled-components';
import TodayStatsBar from './tickets/TodayStatsBar';
import { getTicets } from '../modules/tickets/selectors';
import { styled } from '@mui/system';
import { getClientLayout } from '../modules/layout/selectors';
import { Layout } from '../setup/Layout';
import { useTranslation } from 'react-i18next';
import TicketBottomBar from './tickets/TicketsBottomBar';

const NoTicketsText = styled(Typography)({
  fontWeight: 700,
  fontSize: '2rem',
});

const Tickets = () => {
  const { t } = useTranslation(['common']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const layout = useAppSelector((x) => getClientLayout(x.layout));
  const theme = useTheme();
  const isDownSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const tickets = useAppSelector((s) => getTicets(s.tickets));

  useEffect(() => {
    dispatch(fetchTickets());
    dispatch(fetchStats());
    const interval = setInterval(() => {
      console.log('Tickets - scheduled - fetch tickets and stats');
      dispatch(fetchTickets());
      dispatch(fetchStats());
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {!isDownSmallScreen && (
        <ReturnButton onClick={() => navigate('/')} sx={{ my: 2 }}>
          <>{t('common:buttons.back')}</>
        </ReturnButton>
      )}
      {layout === Layout.Mobile && <TodayStatsBar></TodayStatsBar>}
      {tickets.length === 0 && (
        <Box sx={{ p: 2, alignContent: 'center', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', my: 4 }}>
          <NoTicketsText align="center">{t('common:tickets.noTickets').toString()}</NoTicketsText>
        </Box>
      )}
      <MainTickets></MainTickets>

      {tickets.length > 0 && <TicketBottomBar></TicketBottomBar>}
    </>
  );
};

export default Tickets;
