import { Box, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { GreenButton, GreyButton, VioletButton } from '../../../components/styled-components';
import { getBasketSummary, getInvoiceData } from '../../../modules/basket/selectors';
import { clearBasket } from '../../../modules/basket/slice';
import { getPaymentState } from '../../../modules/payment/selectors';
import { clearPayment } from '../../../modules/payment/slice';
import { useAppDispatch, useAppReturnLink, useAppSelector } from '../../../utils/hooks';
import { printInvoice } from '../../../utils/terminal';
import { ConfirmationContent } from '../styles';
import WizardSteps from '../WizardSteps';

const Confirmation = () => {
  const { t } = useTranslation(['common']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const basketSummary = useAppSelector((state) => getBasketSummary(state.basket));
  const paymentStatus = useAppSelector((state) => getPaymentState(state.payment));
  const invoiceData = useAppSelector((state) => getInvoiceData(state.basket));
  const primaryLink = useAppReturnLink();

  const theme = useTheme();
  const isDownMDScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClosePaymentButtonClick = async () => {
    dispatch(clearBasket());
    dispatch(clearPayment());
    navigate(primaryLink);
  };

  const handleQRCodeButtonClick = async () => {
    dispatch(clearBasket());
    navigate('/qrcodes');
  };

  const handlePrint = async () => {
    const printState = await printInvoice(paymentStatus?.orderId ?? '', paymentStatus?.tickets ?? [], invoiceData?.nip);
    console.log('Print status: ' + printState);
  };

  return (
    <>
      <WizardSteps activeStep={4}>
        <Grid container sx={{ justifyContent: 'center', display: 'flex' }}>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <Box component="img" src="/images/terminal_confirmation.png" sx={{ height: isDownMDScreen ? '130px' : 'auto' }}></Box>
          </Grid>
          <Grid item xs={12} sx={{ justifyContent: 'center', display: 'flex', mt: 3 }}>
            <ConfirmationContent align="center">
              {t('common:payments.payed').toString()}!
              <br />
              {t('common:payments.paymentConfirmed').toString()}
              <br />
              {t('common:payments.paymentAmountText').toString()} {basketSummary.amount.toFixed(2)} {basketSummary.currency}.
            </ConfirmationContent>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <Stack direction="row" spacing={2}>
              <GreyButton onClick={handleQRCodeButtonClick}>
                <Typography>{t('common:buttons.qrCode').toString()}</Typography>
              </GreyButton>
              <GreenButton onClick={handleClosePaymentButtonClick}>
                <Typography>{t('common:buttons.complete').toString()}</Typography>
              </GreenButton>
            </Stack>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <Stack direction="row" spacing={2}>
              <VioletButton onClick={handlePrint}>
                <Typography>{t('common:payments.printAgain').toString()}</Typography>
              </VioletButton>
            </Stack>
          </Grid>
        </Grid>
      </WizardSteps>
    </>
  );
};

export default Confirmation;
