import { Box, Button, ListItemAvatar, ListItemButton, ListItemText, Typography, styled, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CircleIcon from '@mui/icons-material/Circle';
import { useState } from 'react';
import { TicketSummary } from '../../modules/basket/models';
import { TicketType } from '../../modules/tickets/models';
import { useTranslation } from 'react-i18next';

interface TicketListItemProps {
  ticketSummary: TicketSummary;
  name: string;
  color: string;
  ticketType: TicketType;
  onDelete: (ticketType: TicketType) => void;
}

const TicketNameText = styled(ListItemText)(({ theme }) => ({
  fontSize: '25px',
  '& span': { fontSize: '25px' },
  [theme.breakpoints.down('sm')]: {
    fontSize: '20px',
    '& span': { fontSize: '20px' },
  },
}));

const SummaryText = styled(Typography)(({ theme }) => ({
  fontSize: '25px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '20px',
  },
}));

const TicketListItem = (props: TicketListItemProps) => {
  const { t } = useTranslation(['common']);
  const [showDelete, setShowDelete] = useState(false);
  return (
    <>
      {props.ticketSummary.count > 0 && (
        <Box sx={{ display: 'flex', my: 3, minHeight: '93px' }}>
          <ListItemButton sx={{ bgcolor: 'background.paper', left: showDelete ? -15 : 0 }} onClick={() => setShowDelete(!showDelete)}>
            <ListItemAvatar>
              <CircleIcon sx={{ fontSize: '15px' }} htmlColor={props.color}></CircleIcon>
            </ListItemAvatar>
            <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
              <TicketNameText primary={props.name} />
              <SummaryText sx={{ mr: 5, fontWeight: 400 }}>
                {props.ticketSummary.count} <>{t('common:tickets.units')}</>
              </SummaryText>
              <SummaryText sx={{ fontWeight: '600' }}>
                {props.ticketSummary.amount} {props.ticketSummary.currency}
              </SummaryText>
            </Stack>
          </ListItemButton>
          {showDelete && (
            <Button sx={{ backgroundColor: '#FF4242', minWidth: '70px' }} onClick={() => props.onDelete(props.ticketType)}>
              <DeleteIcon htmlColor="white"></DeleteIcon>
            </Button>
          )}
        </Box>
      )}
    </>
  );
};

export default TicketListItem;
