import { Divider, Grid, Stack, Typography, styled, useMediaQuery, useTheme, SxProps } from '@mui/material';
import TicketList from './summary/TicketList';
import { useAppDispatch, useAppSelector } from '../utils/hooks';
import { getBasketSummary } from '../modules/basket/selectors';
import { AddCompanyDataButton, PaymentButton, ReturnButton } from '../components/styled-components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getAppDetails, IsPrinterConnected } from '../utils/terminal';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { getReturnUrl } from '../utils/navigationHelper';
import { clearBasket } from '../modules/basket/slice';
import { useProcessPayment, useSinglePaymentType } from '../modules/payment/hooks';
import { Suspense, useEffect, useState } from 'react';
import useDisplayToolbarReturnButton from '../layout/layoutHooks';
import React from 'react';
import { useTranslation } from 'react-i18next';

const PrinterWarningDialog = React.lazy(() => import('./summary/PrinterWarningDialog'));

const PlusIcon = styled(AddCircleIcon)({
  fontSize: '2rem',
  width: '35px',
  '&>:nth-of-type(1)': {
    fontSize: '2rem',
  },
});

const Summary = () => {
  const { t } = useTranslation(['common']);
  const displayToolbarReturnButton = useDisplayToolbarReturnButton();
  const basketSummary = useAppSelector((state) => getBasketSummary(state.basket));
  const [paymentButtonText, setPaymentButtonText] = useState<string>('Zapłać');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [singlePaymentType] = useSinglePaymentType();
  const [processPayment] = useProcessPayment(singlePaymentType);
  const theme = useTheme();
  const isDownMDScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [openPrinterWarning, setOpenPrinterWarning] = useState<boolean>(false);

  const handlePaymentClick = async (): Promise<void> => {
    const appDetails = await getAppDetails();
    if (appDetails.hotspotRequired) {
      console.log('[Summary] Hotspot is required');
      const isHotspotEnabled = window.BtDevice.isHotspotEnabled();
      if (!isHotspotEnabled) {
        console.log('[Summary] Hotspot is NOT enabled');
        window.BtDevice.openHotspotSettings();
        return;
      }
      console.log('[Summary] Hotspot is enabled');
    } else {
      console.log('[Summary] Hotspot is NOT required');
    }

    console.log('[Summary] Single payment type: ' + singlePaymentType);

    if (appDetails.fiscalPrinterRequired) {
      const isPrinterConnected = await IsPrinterConnected();
      console.log('[Summary] isPrinterConnected', isPrinterConnected);
      if (!isPrinterConnected) {
        setOpenPrinterWarning(true);
        return;
      }
    }

    if (singlePaymentType) {
      await processPayment();
    } else {
      navigate('/payment_wizard?r=/summary');
    }
  };

  const handleAddCompanyData = () => {
    navigate('/summary/company-details?r=/summary');
  };

  const handleReturnClicked = () => {
    const url = getReturnUrl(searchParams, '/tickets');
    if (url === '/') {
      dispatch(clearBasket());
    }
    navigate(url);
  };

  useEffect(() => {
    setPaymentButtonText(singlePaymentType ? t('common:buttons.payment') : t('common:buttons.pay'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singlePaymentType]);

  const mdButtonsBoxStyles = { pt: 4 } as SxProps;
  const lgButtonsBoxStyles = { display: 'flex', justifyContent: 'flex-end', pt: 4 } as SxProps;

  return (
    <>
      {!displayToolbarReturnButton && (
        <Grid container>
          <Grid item sx={{ py: 3 }}>
            <ReturnButton onClick={handleReturnClicked}>
              <>{t('common:buttons.back')}</>
            </ReturnButton>
          </Grid>
        </Grid>
      )}
      <Grid container sx={{ mt: 4 }}>
        <Grid item xs={12}>
          <Typography sx={{ fontSize: '25px', lineHeight: '37px' }}>
            <>{t('common:summary.summary')}</>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TicketList></TicketList>
          <Divider sx={{ mb: 2 }}></Divider>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Stack direction="row" spacing={1}>
            <Typography sx={{ fontSize: '25px', display: 'block', fontWeight: '400' }}>
              <>{t('common:summary.ticketCount')}:</>
            </Typography>
            <Typography sx={{ fontSize: '25px', display: 'block', fontWeight: '600', color: '#9464FC' }}>
              {basketSummary.count} <>{t('common:tickets.units')}</>
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Stack direction="row" spacing={1}>
            <Typography sx={{ fontSize: '34px', fontWeight: 600 }}>
              <>{t('common:tickets.sum')}:</>
            </Typography>
            <Typography sx={{ fontSize: '34px', fontWeight: 600, color: '#9464FC' }}>
              {basketSummary.amount}.00 {basketSummary.currency}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 4 }}>
        <Grid item xs={12} sx={isDownMDScreen ? mdButtonsBoxStyles : lgButtonsBoxStyles}>
          <Stack direction={isDownMDScreen ? 'column' : 'row'} spacing={2}>
            <AddCompanyDataButton onClick={handleAddCompanyData} sx={{ alignSelf: 'center' }} startIcon={<PlusIcon />}>
              <>{t('common:summary.companyData')}</>
            </AddCompanyDataButton>
            <PaymentButton onClick={handlePaymentClick} sx={{ alignSelf: 'center' }}>
              {paymentButtonText}
            </PaymentButton>
          </Stack>
        </Grid>
      </Grid>
      <Suspense>
        <PrinterWarningDialog open={openPrinterWarning} handleClose={() => setOpenPrinterWarning(() => false)}></PrinterWarningDialog>
      </Suspense>
    </>
  );
};

export default Summary;
