export const namespaces = {
  pages: {
    hello: 'pages.hello',
  },
  common: 'common',
};

export const languages = {
  en: 'en',
  pl: 'pl',
};
