import { InvoiceData, TicketType } from '../tickets/models';
import { BasketStateModel, BasketTicket, TicketSummary } from './models';

export const getBasketTicket = (state: BasketStateModel, variantId: number) => {
  return state.tickets.find((x) => x.ticket.variantId === variantId);
};

export const getBasketTicketsByVariant = (state: BasketStateModel, variantId: number): BasketTicket[] => {
  return state.tickets.filter((x) => x.ticket.variantId === variantId);
};

export const getBasketTickets = (state: BasketStateModel) => {
  return state.tickets;
};

export const getBasketAmount = (state: BasketStateModel): number => {
  return state.tickets.map((t) => t.ammount).reduce((sum, x) => sum + x, 0);
};

export const getBasketTicketCount = (state: BasketStateModel): number => {
  return state.tickets.map((t) => t.count).reduce((sum, x) => sum + x, 0);
};

export const getBasketSummary = (state: BasketStateModel, ticketType?: TicketType, variantId?: number): TicketSummary => {
  let tickets = [] as BasketTicket[];

  if (ticketType !== undefined) {
    tickets = state.tickets.filter((x) => x.ticket.type === ticketType);
  } else if (variantId !== undefined) {
    tickets = state.tickets.filter((x) => x.ticket.variantId === variantId);
  } else {
    tickets = state.tickets;
  }
  return {
    amount: tickets.map((t) => t.ammount).reduce((sum, x) => sum + x, 0),
    count: tickets.map((t) => t.count).reduce((sum, x) => sum + x, 0),
    currency: tickets.length > 0 ? tickets[0]?.ticket?.price?.currency : 'PLN',
  } as TicketSummary;
};

export const getOtherFreeTicketsBasketSummary = (state: BasketStateModel) => {
  let tickets = [] as BasketTicket[];

  tickets = state.tickets.filter((x) => x.ticket.type === TicketType.FREE && x.ticket.variantId !== 146444 && x.ticket.variantId !== 146445);
  return {
    amount: tickets.map((t) => t.ammount).reduce((sum, x) => sum + x, 0),
    count: tickets.map((t) => t.count).reduce((sum, x) => sum + x, 0),
    currency: tickets.length > 0 ? tickets[0].ticket.price.currency : 'PLN',
  } as TicketSummary;
};

export const getInvoiceData = (state: BasketStateModel): InvoiceData | undefined => {
  return state.invoiceData;
};
