import { useEffect, useState } from 'react';
import { Box, Divider, Paper, styled, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Stack } from '@mui/system';
import { t } from 'i18next';
import { getTicets } from '../../modules/tickets/selectors';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { HeaderText } from '../ticket-status/ValidTicket';
import TicketBottomBar from '../tickets/TicketsBottomBar';
import { fetchTickets } from '../../modules/tickets/operations';
import { getEntryCarPlates } from '../../utils/terminal';
import { TicketSubType } from '../../modules/tickets/models';
import { RedButton } from '../../components/styled-components';

const TicketName = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '20px',
  color: 'white',
}));

const PriceText = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: '25px',
  color: 'white',
}));

const getImgae = (typeSubCategory: TicketSubType | undefined) => {
  if (typeSubCategory === undefined) {
    return '';
  }
  switch (typeSubCategory) {
    case TicketSubType.PassengerCar:
      return '/images/pole/car.png';
    case TicketSubType.Bus:
      return '/images/pole/bus.png';
    case TicketSubType.Camper:
      return '/images/pole/bus.png';
    case TicketSubType.Motorcycle:
      return '/images/pole/scooter.png';
    case TicketSubType.DisabledDriver:
      return '/images/pole/disabled.png';
    default:
      return '';
  }
};

const PoleTickets = () => {
  const dispatch = useAppDispatch();
  const tickets = useAppSelector((x) => getTicets(x.tickets));
  const [carPlates, setCarPlates] = useState<string>('');

  useEffect(() => {
    dispatch(fetchTickets());
    const interval = setInterval(() => {
      console.log('Tickets - scheduled - fetch tickets');
      dispatch(fetchTickets());
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      console.log('Tickets - scheduled - get car plates');
      getCarPlates();
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const getCarPlates = async () => {
    var entryCarPlates = await getEntryCarPlates();
    setCarPlates(entryCarPlates);
  };

  return (
    <>
      <>
        <Box sx={{ pt: 3, pb: 1 }}>
          <HeaderText>
            <>{t('common:tickets.mainTicketWindowHeader')}</>
          </HeaderText>
        </Box>
        <Grid2 container spacing={4}>
          {tickets.map((ticket) => (
            <Grid2 xs={12} lg={6}>
              <Paper sx={{ width: 4 / 4, height: '120px', backgroundColor: '#4398D1', borderRadius: '20px' }}>
                <Grid2 container>
                  <Grid2 xs={4}>
                    <Box component="img" src={getImgae(ticket.ticketSubType)} sx={{ width: '140px' }}></Box>
                  </Grid2>
                  <Grid2 xs={8}>
                    <Stack direction="column">
                      <TicketName>{tickets[0]?.name}</TicketName>
                      <Divider sx={{ borderTop: '1px dashed white' }}></Divider>
                      <PriceText>
                        {tickets[0]?.price.amount} {tickets[0]?.price.currency}
                      </PriceText>
                    </Stack>
                  </Grid2>
                  {/* <Grid2 xs={4}>{tickets.length > 0 && <TileOperationsBox ticket={tickets[0]}></TileOperationsBox>}</Grid2> */}
                </Grid2>
              </Paper>
            </Grid2>
          ))}
        </Grid2>
      </>
      <Box sx={{ position: 'absolute', bottom: '0px', width: 'calc(100% - 48px)' }}>
        <Typography sx={{ textAlign: 'center' }}>{carPlates}</Typography>

        <Typography sx={{ textAlign: 'center' }}>Możesz wybrać tylko jeden bilet parkingowy</Typography>
      </Box>
    </>
  );
};

export default PoleTickets;
