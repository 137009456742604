import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Slice } from '../../types';
import { OrderStatus, PaymentStateModel } from './models';
import { startPayment } from './operations';

const initialState = {
  paymentStatus: null,
} as PaymentStateModel;

const paymentSlice = createSlice({
  name: Slice.Payment,
  initialState: initialState,
  reducers: {
    clearPayment: (state) => {
      state.paymentStatus = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(startPayment.fulfilled, (state, action: PayloadAction<OrderStatus | null>) => {
      state.paymentStatus = action.payload;
    });
  },
});

export const { clearPayment } = paymentSlice.actions;
export default paymentSlice.reducer;
