import { StatModel, Ticket, TicketDetails, TicketOption, TicketWarning } from '../modules/tickets/models';

export const tickets = [
  {
    variantId: 146442,
    name: 'Bilet ulgowy',
    type: 'REDUCED',
    available: 400,
    price: {
      amount: 6,
      currency: 'PLN',
    },
    question: null,
  },
  {
    variantId: 146443,
    name: 'Bilet normalny',
    type: 'NORMAL',
    available: 400,
    price: {
      amount: 12,
      currency: 'PLN',
    },
    question: null,
  },
  {
    variantId: 146444,
    name: 'Bilet dla dziecka do 7 roku życia',
    type: 'REDUCED',
    available: 400,
    price: {
      amount: 20,
      currency: 'PLN',
    },
    question: null,
  },
  {
    variantId: 146445,
    name: 'Bilet z Kartą Dużej Rodziny (KDR)',
    type: 'FREE',
    available: 400,
    price: {
      amount: 0,
      currency: 'PLN',
    },
    question: {
      questionId: 8043,
      question: 'Podaj nr Karty Dużej Rodziny',
    },
  },
  {
    variantId: 146446,
    name: 'Bilet dla przewodnika górskiego z uprawnieniami na Sudety',
    type: 'FREE',
    available: 400,
    price: {
      amount: 0,
      currency: 'PLN',
    },
    question: null,
  },
  {
    variantId: 146447,
    name: 'Zgoda Dyrektora PN na bezpłatny wstęp',
    type: 'FREE',
    available: 400,
    price: {
      amount: 0,
      currency: 'PLN',
    },
    question: {
      questionId: 8044,
      question: 'Podaj datę zgody Dyrektora PN na bezpłatny wstęp',
    },
  },
  {
    variantId: 146448,
    name: 'Mieszkańcy gmin graniczących z PNGS',
    type: 'FREE',
    available: 400,
    price: {
      amount: 0,
      currency: 'PLN',
    },
    question: null,
  },
] as Ticket[];

export const tpnParkingTickets = [
  {
    variantId: 30613,
    name: 'BUS / KAMPER 5-8 m długości - parkingi przy szlaku do Morskiego Oka',
    type: 'PARKING',
    available: 1,
    price: {
      amount: 70,
      currency: 'PLN',
    },
    question: {
      questionId: 120,
      question: 'Proszę podać pełny numer rejestracyjny pojazdu',
    },
  },
  {
    variantId: 19183,
    name: 'SAMOCHÓD OSOBOWY  do 5 m długości - parkingi przy szlaku do Morskiego Oka',
    type: 'PARKING',
    available: 25,
    price: {
      amount: 45,
      currency: 'PLN',
    },
    question: {
      questionId: 120,
      question: 'Proszę podać pełny numer rejestracyjny pojazdu',
    },
  },
] as Ticket[];

export const poleTickets = [
  {
    variantId: 30613,
    name: 'Samochód osobowy',
    type: 'PARKING',
    available: 1,
    price: {
      amount: 70,
      currency: 'PLN',
    },
    question: {
      questionId: 120,
      question: 'Proszę podać pełny numer rejestracyjny pojazdu',
    },
  },
  {
    variantId: 19183,
    name: 'Bus',
    type: 'PARKING',
    available: 25,
    price: {
      amount: 45,
      currency: 'PLN',
    },
    question: {
      questionId: 120,
      question: 'Proszę podać pełny numer rejestracyjny pojazdu',
    },
  },
] as Ticket[];

export const tpnTickets = [
  {
    variantId: 164134,
    name: '[integracja] SAMOCHÓD OSOBOWY  do 5 m długości - parkingi przy szlaku do Morskiego Oka',
    type: 'PARKING',
    available: 1259,
    price: {
      amount: 45,
      currency: 'PLN',
    },
    question: {
      questionId: 10853,
      question: 'Proszę o podanie numeru rejestracyjnego pojazdu',
    },
  },
] as Ticket[];

export const pools = [
  { date: '2022-07-03T13:00:00+00:00', availableTickets: 400, originalAvailability: 400 },
  { date: '2022-07-03T21:59:00+00:00', availableTickets: 400, originalAvailability: 400 },
  { date: '2022-07-04T13:00:00+00:00', availableTickets: 400, originalAvailability: 400 },
  { date: '2022-07-04T21:59:00+00:00', availableTickets: 400, originalAvailability: 400 },
  { date: '2022-07-05T13:00:00+00:00', availableTickets: 400, originalAvailability: 400 },
  { date: '2022-07-05T21:59:00+00:00', availableTickets: 400, originalAvailability: 400 },
];

export const ticketDetails = {
  uuid: 'A4F1D33E8C',
  orderId: 'D468878682',
  qrCode: 'https://sandbox.e-bilet.online/facility/1/tickets?uuid=b0a5ba15-f61e-4c51-9f67-05c481f5b135',
  name: '[integracja] SAMOCHÓD OSOBOWY  do 5 m długości - parkingi przy szlaku do Morskiego Oka, 06.07.2022',
  variantId: 164134,
  variantName: '[integracja] SAMOCHÓD OSOBOWY  do 5 m długości - parkingi przy szlaku do Morskiego Oka',
  price: 45,
  currency: 'PLN',
  vat: 23,
  paymentState: 'pending',
  paymentDate: null,
  useLimit: 1,
  useCount: 1,
  validSince: '2022-07-05T22:00:00+00:00',
  validUntil: '2022-07-06T21:59:59+00:00',
  question: { questionAnswerId: 11046, question: 'Proszę o podanie numeru rejestracyjnego pojazdu', answer: 'TK1234' },
} as TicketDetails;

export const stats = [
  {
    date: '2022-07-27T22:00:00+00:00',
    availableTickets: 1260,
    originalAvailability: 623,
  },
  {
    date: '2022-07-28T22:00:00+00:00',
    availableTickets: 1260,
    originalAvailability: 623,
  },
  {
    date: '2022-07-29T22:00:00+00:00',
    availableTickets: 1260,
    originalAvailability: 623,
  },
] as StatModel[];

export const ticketDetailsInvalid = {
  uuid: 'cf7885b9-41fb-4bdf-a5cf-2c713340d9fd',
  ticketId: '2E84F6DF2A',
  orderId: '8ADA5A3BDF',
  qrCode: 'https://sandbox.e-bilet.online/facility/1/tickets?uuid=cf7885b9-41fb-4bdf-a5cf-2c713340d9fd',
  name: '[integracja] SAMOCHÓD OSOBOWY  do 5 m długości - parkingi przy szlaku do Morskiego Oka, 30.08.2022',
  variantId: 164134,
  variantName: 'Samochód osobowy',
  price: 45,
  currency: 'PLN',
  vat: 23,
  paymentState: 'completed',
  paymentDate: '2022-08-30 19:49:38',
  useLimit: 1,
  useCount: 1,
  validSince: '2022-08-29T22:00:00+00:00',
  validUntil: '2022-08-30T21:59:59+00:00',
  question: { questionAnswerId: 13168, question: 'Proszę o podanie numeru rejestracyjnego pojazdu', answer: 'TKI1234' },
  ticketWarnings: [TicketWarning.TicketNotPaid],
  ticketOptions: [TicketOption.TicketPayment] as TicketOption[],
  order: { id: '8ADA5A3BDF', totalAmount: 45 },
} as TicketDetails;

export const createOrderResponse = {
  orderId: '803E3CFDBB',
  totalAmount: 12,
  tickets: [
    {
      uuid: '14d4ba0f-a08d-4a1c-8d21-f6211acb46ef',
      ticketId: 'B24D203A90',
      orderId: '803E3CFDBB',
      qrCode: 'https://sandbox.e-bilet.online/facility/1/tickets?uuid=14d4ba0f-a08d-4a1c-8d21-f6211acb46ef',
      name: '[integracja wewnętrzna] Wstęp na trasę turystyczną Błędne Skały, Bilet normalny, 18.10.2022 23:59',
      variantId: 146443,
      variantName: 'Bilet normalny',
      price: '12',
      currency: 'PLN',
      vat: 8,
      paymentState: 'pending',
      paymentDate: null,
      useLimit: 1,
      useCount: 0,
      validSince: '2022-10-17T22:00:00+00:00',
      validUntil: '2022-10-18T21:59:59+00:00',
      question: null,
      ticketWarnings: ['ticketNotPaid'],
      ticketOptions: ['ticketPayment'],
      order: { id: '803E3CFDBB', totalAmount: 12 },
    },
    {
      uuid: '1f9c0348-ddd0-4e10-9497-acc5a2ac756f',
      ticketId: '9E0C5E7D6E',
      orderId: '803E3CFDBB',
      qrCode: 'https://sandbox.e-bilet.online/facility/1/tickets?uuid=1f9c0348-ddd0-4e10-9497-acc5a2ac756f',
      name: '[integracja wewnętrzna] Wstęp na trasę turystyczną Błędne Skały, Bilet dla przewodnika górskiego z uprawnieniami na Sudety, 18.10.2022 23:59',
      variantId: 146446,
      variantName: 'Bilet dla przewodnika górskiego z uprawnieniami na Sudety',
      price: '0',
      currency: 'PLN',
      vat: 8,
      paymentState: 'pending',
      paymentDate: null,
      useLimit: 1,
      useCount: 0,
      validSince: '2022-10-17T22:00:00+00:00',
      validUntil: '2022-10-18T21:59:59+00:00',
      question: null,
      ticketWarnings: ['ticketNotPaid'],
      ticketOptions: ['ticketPayment'],
      order: { id: '803E3CFDBB', totalAmount: 12 },
    },
  ],
};
