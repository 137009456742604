import { Box, styled, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import CenteredBox from './CenteredBox';

const ScanningArrow = () => {
  return <Box component={motion.img} transition={{ duration: 2, repeat: Infinity, repeatDelay: 0 }} animate={{ y: [0, 20, -10, 0] }} src="/images/pole/arrow_down.png" sx={{ width: '100px' }}></Box>;
};

const InfoText = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: '30px',
}));

const ScanPage = () => {
  const navigate = useNavigate();
  return (
    <CenteredBox onClick={() => navigate('/')}>
      <Stack direction="column" spacing={5} justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
        <Box component="img" src="/images/pole/qrcode.png" sx={{ width: '200px' }}></Box>
        <InfoText>Przyłóż bilet z kodem QR do czytnika</InfoText>
        <ScanningArrow></ScanningArrow>
      </Stack>
    </CenteredBox>
  );
};

export default ScanPage;
